.summary-component {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: end;
  font-size: var(--font-size-body-2);
  color: var(--color-whisper);
  justify-self: center;
}

@media all and (max-width: 1200px) {
  .summary-component {
    order: 1;
    padding-bottom: var(--indent-high);
  }
}

@media all and (max-width: 570px) {
  .summary-component {
    width: 100%;
    grid-auto-flow: row;
    justify-content: stretch;
  }

  .package-component {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }
}

.package-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 var(--size-one-pt);
  height: calc(4 * var(--size-one-pt));
}

.package-component {
  width: calc(16 * var(--size-one-pt));
}

.package-component.package-locked .package-header {
  cursor: not-allowed;
  filter: grayscale(0.5);
}

.package-select-circle {
  display: flex;
  width: calc(3 * var(--size-one-pt));
  height: calc(3 * var(--size-one-pt));
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.package-component.selected .package-select-circle::after {
  content: '';
  display: block;
  margin: auto;
  width: calc(2 * var(--size-one-pt));
  height: calc(2 * var(--size-one-pt));
  border-radius: 100%;
  background-color: var(--color-whisper);
  animation: fade-in ease 0.15s;
}

@keyframes fade-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.package-label {
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--font-size-body-3);
  margin-left: var(--size-one-pt);
}
.summary-titles,
.package-content {
  position: relative;
}
.summary-titles {
  color: var(--color-silver-chalice);
}

.summary-rows {
  height: 100%;
}

.summary-details-wrapper {
  line-height: calc(4 * var(--size-one-pt));
}

.summary-details {
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  padding: 0 var(--size-one-pt);
  white-space: nowrap;
}
.summary-details:hover {
  text-decoration: underline;
}

.summary-details.quote-initializing {
  cursor: not-allowed;
}
.summary-details.quote-initializing:hover {
  text-decoration: none;
}

@media all and (max-width: 570px) {
  .summary-rows,
  .package-content {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }

  .summary-rows {
    grid-auto-columns: 25%;
    justify-content: end;
  }
}

.package-component:not(.selected) .package-content {
  background-color: transparent !important;
}

.package-component.selected .package-content {
  box-shadow: inset 0 0 15rem 0 rgba(70, 70, 70, 0.35);
}

.row-name,
.package-data {
  text-align: right;
}
.row-name,
.package-data > * {
  padding: 0 var(--size-one-pt);
}

.package-component:not(.package-locked) .package-content:hover .package-action,
.package-component.package-locked.selected .package-content:hover .package-action {
  display: flex;
}

.package-action {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  line-height: calc(2 * var(--size-one-pt));
  word-spacing: 100vw;
}
.package-action.quote-initializing {
  cursor: not-allowed;
}

@media all and (max-width: 570px) {
  .package-action {
    word-spacing: initial;
  }
}

.package-component.selected.package-override {
  border: 1px solid var(--color-white);
}

.package-component.package-1 .package-header,
.package-component.package-1 .package-content {
  background-color: var(--color-medium-orchid);
}

.package-component.package-1 .package-action {
  background-color: var(--color-studio);
}

.package-component.package-2 .package-header,
.package-component.package-2 .package-content {
  background-color: var(--color-java);
}

.package-component.package-2 .package-action {
  background-color: var(--color-surfie-green);
}

.package-component.package-3 .package-header,
.package-component.package-3 .package-content {
  background-color: var(--color-yellow-sea);
}

.package-component.package-3 .package-action {
  background-color: var(--color-mango-tango);
}

.package-component.package-4 .package-header,
.package-component.package-4 .package-content {
  background-color: var(--color-cornflower-royal-blue);
}

.package-component.package-4 .package-action {
  background-color: var(--color-persian);
}
