.checkbox-input-abstract {
  grid-template-columns: auto;
}
.checkbox-input-abstract.small {
  min-height: calc(7 * var(--size-quarter-pt));
}

.checkbox-input-component label:hover {
  cursor: pointer;
}

.checkbox-input-component > input {
  display: none;
}

.checkbox-input-component > label {
  display: inline-block;
}

.checkbox-input-component .icon-check-box {
  color: var(--color-black-900);
}

.checkbox-input-component .icon-check-box-checked {
  color: var(--color-primary-500);
}

.checkbox-input-component.small .icon-check-box {
  font-size: calc(2.5 * var(--size-one-pt));
  line-height: calc(2.5 * var(--size-one-pt));
}

.checkbox-input-component > input:disabled + label,
.checkbox-input-component > input:disabled + label .icon-check-box {
  cursor: not-allowed;
  color: var(--color-black-200);
}
