.pagination button.prev-page:before {
  display: block;
  transform: rotate(-180deg);
}

.pagination button:not(.active):focus {
  font-weight: 600;
}

.pagination .first-page,
.pagination .last-page {
  font-weight: 600;
}
