.header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: var(--color-primary-500) !important;
  z-index: 1100;
  color: var(--color-alabaster);
}

.header-wrapper .max-width-container {
  display: flex;
  align-items: center;
}

.header-wrapper .header-name-label {
  display: block;
  padding-right: calc(2 * var(--size-one-pt));
  text-align: center;
  margin: auto 0;
  font-family: Poppins, 'Open Sans', sans-serif;
  font-weight: 500;
}

@media all and (max-width: 870px) {
  .header-wrapper .header-name-label {
    display: none;
  }
}

.header-wrapper > .header-flex-container {
  margin: 0 auto !important;
  padding: 0 var(--size-one-pt) 0 0 !important;
  background: var(--color-primary-500) !important;
  box-shadow: none;
}

.header-wrapper > .header-flex-container > div:first-of-type {
  display: flex;
  margin: 0 !important;
  align-items: center;
}

.header-wrapper .header-avatar {
  display: block;
  width: calc(4 * var(--size-one-pt));
  height: calc(4 * var(--size-one-pt));
  line-height: calc(4 * var(--size-one-pt));
  text-align: center;
  border: 1px solid var(--color-black-100);
  border-radius: 100%;
  background: var(--color-black-050);
  color: var(--color-black-600);
  margin: auto 0;
  cursor: pointer;
  font-family: Poppins, 'Open Sans', sans-serif;
  font-weight: 500;
}

.sub-header-component {
  position: absolute;
  top: 5rem;
  left: 0;
  padding: 0.2rem 22.5rem 0.2rem 2rem;
  width: 100%;
  height: 2.6rem;
  line-height: 1.8rem;
  font-size: 2rem;
  font-weight: bold;
  opacity: 0.9;
}

.sub-header-component .sub-header-sub-message {
  float: right;
  margin-top: 0.2rem;
}

.header-wrapper .header-flex-container {
  display: flex;
  justify-content: space-between;
  height: calc(6 * var(--size-one-pt));
}

.header-wrapper .header-username {
  display: flex;
}

.header-wrapper .header-dropdown-menu {
  display: none;
  position: absolute;
  right: var(--size-one-pt);
  top: calc(7 * var(--size-one-pt));
  background-color: white;
  color: var(--color-shark);
  border-radius: var(--size-half-pt);
  box-shadow: 0 0.4rem 1.6rem rgba(33, 37, 41, 0.24);
}
.header-wrapper .header-dropdown-menu:before {
  display: block;
  content: '';
  position: absolute;
  top: calc(-2 * var(--size-one-pt));
  right: var(--size-one-pt);
  border-color: transparent transparent white transparent;
  border-width: var(--size-one-pt);
  border-style: solid;
}

.header-wrapper .header-dropdown-menu.open {
  display: block;
}

.header-wrapper .header-dropdown-menu .header-dropdown-menu-item {
  padding: var(--size-one-pt) calc(2 * var(--size-one-pt));
  cursor: pointer;
}

.header-wrapper .header-dropdown-menu .header-dropdown-menu-item.selected {
  background-color: var(--color-ghost);
}

.header-wrapper .header-dropdown-menu .header-dropdown-menu-item:hover {
  background-color: var(--color-section-header-bg);
}

.header-wrapper .header-dropdown-menu .header-dropdown-menu-item .icon-exit {
  font-size: var(--font-size-body-1);
  vertical-align: middle;
  padding-right: var(--size-one-pt);
}
.header-wrapper .header-dropdown-menu .header-dropdown-menu-item:first-child {
  border-top-left-radius: var(--size-half-pt);
  border-top-right-radius: var(--size-half-pt);
}
.header-wrapper .header-dropdown-menu .header-dropdown-menu-item:last-child {
  border-bottom-left-radius: var(--size-half-pt);
  border-bottom-right-radius: var(--size-half-pt);
}

.header-wrapper .header-dropdown-menu .header-dropdown-menu-item img {
  width: calc(3 * var(--size-one-pt));
}

.header-wrapper .header-dropdown-menu .header-dropdown-menu-item > div {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-column-gap: 5px;
  align-items: center;
}

.header-wrapper .header-dropdown-menu > .separator {
  background-color: var(--color-section-header-bg);
  height: 1px;
  display: block;
  margin: var(--size-half-pt) 0;
}
