.two-sections-wrapper {
  display: block;
  height: 6.5rem;
}

.half-left-section,
.half-right-section {
  display: block;
  height: 6.5rem;
  float: left;
  width: 48%;
  margin-right: 2%;
}

.half-right-section {
  margin-right: 0;
  margin-left: 2%;
}

.submit-header {
  display: block;
  text-align: center;
  margin: var(--indent-standard) 0;
  font-size: var(var(--font-size-body-1));
  color: var(--color-oslo-gray);
  line-height: 1.5em;
  font-weight: 600;
}

.submit-title-wrapper {
  overflow: hidden;
  width: 100%;
}

.submit-title-text {
  display: block;
  text-align: center;
  font-size: var(var(--font-size-body-1));
  font-weight: 400;
  line-height: 1.5em;
  margin-top: 0.5em;
}

.submit-dialog {
  background: rgb(233, 236, 239);
  padding: 1.5rem 3rem 3rem 3rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.submit-dialog-content {
  min-width: 60rem;
  width: auto;
}

@media all and (max-width: 600px) {
  .submit-dialog-content {
    width: inherit;
  }
}

.submit-dialog-header {
  height: 14.1rem;
}

.submit-dialog-actions {
  justify-content: center;
}

.submit-label {
  display: block;
  color: #000;
  margin: 0.5rem 0;
}

.complete-quote-tracking-id input {
  text-align: end;
  padding-right: 0.5rem !important;
}
