.li-category-totals {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 1.6rem 0 1.2rem 0;
  padding: 0 4.4rem 0 1.6rem;
  line-height: 3rem;
  position: relative;
  align-items: center;
  pointer-events: none;
}

.li-category-totals > .title,
.li-category-totals > .value {
  display: block;
  margin: 0;
  padding: 0.5rem 1rem 0 0;
  box-sizing: border-box;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  width: 10%;
}
.li-category-totals > .title {
  text-align: right;
  white-space: pre;
}
.li-category-totals > .title:nth-of-type(2) {
  width: 10%;
}

.li-category-totals > .value {
  font-weight: 600;
  width: 10%;
  text-align: right;
}
.li-category-totals > .value label {
  margin: 0;
  padding: 0;
}

@media (max-width: 1024px) {
  .li-category-totals > .title,
  .li-category-totals > .value {
    width: 14.3%;
  }
}

@media (max-width: 660px) {
  .li-category-totals {
    flex-direction: column;
    align-items: flex-end;
    padding-right: 1rem;
  }

  .li-category-totals > .value {
    display: none;
  }

  .li-category-totals > .title {
    width: auto !important;
  }

  .li-category-totals > .title:nth-of-type(2) {
    margin-top: 1rem;
  }

  .li-category-totals > .title::after {
    content: attr(data-label);
    padding-left: 0.5rem;
    font-weight: bold;
    text-transform: uppercase;
  }
}
