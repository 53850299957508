.location-num-lines-header {
  font-weight: 600;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-column-gap: 5.5rem;
  text-align: end;
}

.location-num-lines-header > div,
.location-num-lines-body > div {
  padding: 1.85rem 1rem;
}

.location-num-lines-body > div:first-of-type {
  padding-left: 0;
  text-align: start;
}

.location-num-lines-body > div:last-of-type {
  padding: 0.8rem 0;
}

.location-num-lines-body {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-column-gap: 5.5rem;
  height: 5.6rem;
  text-align: end;
}

.location-num-lines-body .red-text {
  color: var(--color-red);
}

.location-num-lines-body .input-abstract-component {
  margin: 0;
}
