.error-handler-component {
  position: absolute;
  display: flex;
  bottom: 0;
  width: 100%;
  min-height: 4.8rem;
  background-color: var(--color-hit-gray);
  transform: translateY(100%);
  z-index: 998;
  text-transform: none;
  border-width: 1px;
  border-style: none none solid none;
  border-color: transparent;
  box-sizing: border-box;
}

#root > .error-handler-component {
  position: fixed;
  transform: none;
  z-index: 10000;
}

.error-handler-icon,
.error-handler-content {
  display: flex;
  align-items: center;
}

.error-handler-content {
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 0;
}

#s2s-modal-error-handler-placeholder .error-handler-component {
  position: relative;
  transform: none;
}

@media all and (max-width: 768px) {
  .error-handler-content {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.error-handler-component .message-text,
.error-handler-component .sub-message-text {
  position: relative;
  font-size: var(var(--font-size-body-1));
  font-weight: 600;
}

.error-handler-component .message-text {
  padding-right: var(--indent-high);
}

.error-handler-component .sub-message-text {
  font-weight: 400;
}

@media all and (max-width: 768px) {
  .error-handler-component .sub-message-text {
    padding-left: 0;
  }
}

.error-handler-component .error-handler-icon {
  padding: var(--indent-medium);
  font-size: 2.4rem;
}

.error-handler-component .icon-close {
  padding: 0 var(--indent-high);
  align-self: center;
  font-size: 2.4rem;
  color: var(--color-outer-space);
  user-select: none;
  cursor: pointer;
}

/* ERROR */
.error-handler-component.error {
  background-color: var(--color-danger-025);
  border-bottom-color: var(--color-danger-100);
}
.error-handler-component.error .message-text,
.error-handler-component.error .sub-message-text {
  color: var(--color-danger-700);
}
.error-handler-component.error .error-handler-icon {
  color: var(--color-danger-500);
}
.error-handler-component.error .icon-close {
  color: var(--color-danger-500);
}

/* WARNING */
.error-handler-component.warning {
  background-color: var(--color-warning-025);
  border-bottom-color: var(--color-warning-200);
}
.error-handler-component.warning .message-text,
.error-handler-component.warning .sub-message-text {
  color: var(--color-warning-900);
}
.error-handler-component.warning .error-handler-icon {
  color: var(--color-warning-600);
}
.error-handler-component.warning .icon-close {
  color: var(--color-warning-700);
}

/* INFO */
.error-handler-component.info {
  background-color: var(--color-primary-025);
  border-bottom-color: var(--color-primary-050);
}
.error-handler-component.info .message-text,
.error-handler-component.info .sub-message-text {
  color: var(--color-primary-700);
}
.error-handler-component.info .error-handler-icon {
  color: var(--color-primary-500);
}
.error-handler-component.info .icon-close {
  color: var(--color-primary-400);
}

/* SUCCESS */
.error-handler-component.success {
  background-color: var(--color-success-025);
  border-bottom-color: var(--color-success-100);
}

.error-handler-component.success .message-text,
.error-handler-component.success .sub-message-text {
  color: var(--color-success-800);
}
.error-handler-component.success .error-handler-icon {
  color: var(--color-success-500);
}
.error-handler-component.success .icon-close {
  color: var(--color-success-500);
}
