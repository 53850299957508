.amount-input-component {
  position: relative;
}

.amount-input-component .field-marker {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  padding-left: var(--size-one-pt);
  color: var(--color-silver-chalice);
}

.amount-input-component input {
  padding-right: calc(4 * var(--size-one-pt)) !important;
  padding-left: calc(3 * var(--size-one-pt)) !important;
  text-align: right;
}
