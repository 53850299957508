.signature-wrapper-single-envelope {
  width: 102.4rem;
}

.signature-wrapper-multiple-envelope {
  width: 148rem;
  max-width: 90vw;
}

.signature-body-wrapper {
  display: grid;
  grid-template-rows: auto;
  grid-gap: 0.5rem;
  padding-right: 2rem;
}

.signature-body-wrapper .toggle-input-abstract {
  margin-bottom: 0;
}

.signature-row {
  display: grid;
  grid-column-gap: 4rem;
  align-items: flex-start;
}

.signature-row:last-of-type {
  margin-bottom: 2rem;
}

.signature-row-multiple-envelope {
  grid-template-columns: 0.3fr 1fr 1fr;
}
.signature-row-multiple-envelope.wide-label-row {
  grid-template-columns: 0.5fr 1fr 1fr;
}

.signature-row-single-envelope {
  grid-template-columns: 0.2fr 1fr;
}
.signature-row-single-envelope.wide-label-row {
  grid-template-columns: 0.4fr 1fr;
}

.signature-row .signature-row-title {
  text-align: right;
  font-weight: 600;
  position: relative;
  padding-top: var(--size-one-pt);
}
.signature-row .signature-row-title.required-red:after {
  margin-top: 0.5rem;
}

.bundle-title {
  display: grid;
  grid-column-gap: 4rem;
  font-weight: bold;
}

.bundle-title-single-envelope {
  grid-template-columns: 0.2fr 1fr;
}
.bundle-title-single-envelope.wide-label-row {
  grid-template-columns: 0.4fr 1fr;
}

.bundle-title-multiple-envelope {
  grid-template-columns: 0.3fr 1fr 1fr;
}
.bundle-title-multiple-envelope.wide-label-row {
  grid-template-columns: 0.5fr 1fr 1fr;
}

.signature-block-wrapper:last-child {
  padding: 0 4.4rem 0 2.5rem;
}

.email-and-cc-wrapper {
  display: grid;
  grid-auto-columns: 3fr;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: 1rem;
}

.email-and-cc-title {
  text-align: right;
  padding-top: 1.3rem;
  font-weight: 600;
}

.email-and-cc-inputs_wrapper {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 2rem;
}

.cc-checkbox {
  display: flex;
  align-self: center;
}

.cc-checkbox .checkbox-box {
  position: relative;
  top: initial;
  left: initial;
  margin-left: var(--size-half-pt);
}

.cc-checkbox label {
  display: flex;
  font-weight: 600;
  justify-content: flex-end;
  flex-direction: row-reverse;
  align-items: center;
  cursor: pointer;
}

.document-wrapper {
  display: flex;
  flex-direction: column;
}
.document-wrapper:first-child {
  margin-top: 0.5rem;
}
.document-wrapper .toggle-input-abstract .input-component-wrapper {
  height: initial;
  padding: 0.4rem 0;
}

.docs-pdf-wrapper {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 2rem;
}

.custom-pdf-wrapper,
.additional-cc-emails-wrapper {
  padding-top: 1rem;
}

.custom-pdf-wrapper .pdf-title,
.additional-cc-emails-wrapper .additional-cc-emails-title {
  font-weight: 600;
  text-align: left;
  margin-bottom: 1rem;
}

.signature-message-wrapper {
  display: grid;
  grid-auto-columns: 3fr;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: 1rem;
  position: relative;
}

.signature-message-wrapper div {
  text-align: right;
  font-weight: 600;
  outline: none;
}

.signature-message-wrapper textarea {
  border-radius: var(--radius-light);
  border: 1px solid var(--color-hit-gray);
  padding: 0.8rem 1.2rem;
  resize: none;
}

.signature-message-wrapper textarea[disabled] {
  cursor: not-allowed;
}

.signature-message-wrapper textarea:focus {
  outline: none;
}

.signature-submit-dialog-actions {
  display: grid;
  padding-right: 3.5rem;
  grid-column-gap: 4rem;
  justify-items: flex-start;
}

.signature-actions-single-envelope {
  grid-template-columns: 0.417fr 1fr 1fr;
}

.signature-actions-multiple-envelope {
  grid-template-columns: 0.3fr 1fr 1fr;
}

.signature-submit-dialog-actions .signature-controls:last-child {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.signature-confirm-controll {
  display: flex;
  justify-content: center;
}

.signature-confirm-controll button {
  margin: 0 1rem;
}

.modal-dialog-content.confirm-signature {
  min-width: 55rem;
  max-width: 70rem;
  width: initial;
}

.confirm-signature .message div {
  margin-top: 0.4rem;
}

.confirm-signature .message div:first-of-type,
.confirm-signature .message div:last-of-type {
  margin-bottom: 1rem;
}

.confirm-signature .message span {
  font-weight: 600;
}

.confirm-signature .message div:nth-of-type(n + 1) span:nth-of-type(2) {
  font-weight: 500;
}

.signature-required-red:before {
  content: '*';
  position: absolute;
  color: var(--color-cinnabar);
  top: var(--size-one-pt);
  left: calc(-3 * var(--size-one-pt));
  padding-left: 0.5em;
}

.signature-row .esig-subject-wrapper {
  display: grid;
  grid-template-columns: auto min-content;
  grid-column-gap: 1rem;
  align-items: center;
  margin-bottom: var(--indent-high);
}

.signature-row .esig-subject-wrapper .plus-prefix {
  white-space: nowrap;
  position: relative;
}

.signature-row .esig-subject-wrapper .plus-prefix:before {
  content: '+';
  display: block;
  position: absolute;
  width: 1rem;
  height: 1rem;
  left: -1rem;
}

.signature-row .esig-subject-wrapper .input-abstract-component {
  margin-bottom: 0;
}

.signature-email .input-abstract-error {
  width: 100%;
  left: 0;
}

.signature-message-sub {
  font-size: var(--font-size-body-3);
}

.signature-request-modal .s2s-modal-content.has-on-file-message {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-gap: 4rem;
}
.signature-request-modal .s2s-modal-content.has-on-file-message .signature-body-wrapper {
  padding-right: 0;
}
.signature-request-modal .credit-document-on-file-block h2,
.signature-request-modal .credit-document-on-file-block p {
  margin: 0;
}
.signature-request-modal .credit-document-on-file-block h2 {
  font-size: var(--font-size-body-1);
  font-weight: 700;
}
.signature-request-modal .credit-document-on-file-block p {
  text-align: center;
  font-size: var(--font-size-body-1);
  margin: 2rem 1rem;
  color: var(--color-nevada);
  white-space: nowrap;
}

.additional-cc-emails-wrapper .signature-email.input-abstract-component {
  grid-template-columns: 1.2rem auto;
}

.signature-messageNonCustomizable-wrapper > .textarea-like-string {
  height: calc(14 * var(--size-one-pt));
}

.submit-title-header-hint {
  color: var(--color-black-600);
  font-size: var(--font-size-body-2) !important;
  font-weight: initial !important;
}

.signature-request-modal .s2s-modal-content.has-on-file-message .signature-row.signature-row-single-envelope {
  grid-template-columns: 0.3fr 1fr;
}
