.input-abstract-component {
  position: relative;
  display: grid;
  width: 100%;
  margin-bottom: var(--size-one-pt);
  min-height: calc(5 * var(--size-one-pt));
  align-items: center;
  grid-gap: var(--indent-standard);
  align-content: center;
}

.input-abstract-component.with-type-label {
  margin-bottom: 0;
}

.input-abstract-error {
  position: absolute;
  display: flex;
  top: 100%;
  left: 0;
  margin-top: var(--indent-standard);
  padding: var(--indent-high);
  align-items: center;
  color: white;
  box-shadow: 0 0.4rem 0.8rem rgba(33, 37, 41, 0.4);
  background: var(--color-cinnabar);
  border-radius: 0.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 3;
  animation-name: fade-in;
  animation-duration: 0.125s;
}

.input-abstract-component:focus-within .input-abstract-error {
  display: none;
}

.input-abstract-error-icon {
  margin-right: var(--indent-medium);
  color: var(--color-alabaster);
}

.input-abstract-error.error-for-dates {
  width: 50%;
}

.check-box {
  left: 3.5rem;
}

@media all and (max-width: 768px) {
  .input-abstract-error.error-for-dates {
    width: 100%;
  }
}

@media all and (min-width: 720px) {
  .input-abstract-component {
    grid-auto-columns: 1.5fr;
    grid-template-columns: 1fr;
    grid-auto-flow: column;
  }
  .input-abstract-component.short-input {
    grid-auto-columns: 9rem;
  }
  .input-abstract-component.long-label {
    grid-auto-columns: 20rem;
  }

  .input-abstract-error {
    left: 40.7%;
  }
}

.input-abstract-label-wrapper,
.input-component-wrapper {
  position: relative;
}

.input-abstract-label-wrapper {
  display: grid;
  overflow: hidden;
  line-height: calc(2.5 * var(--size-one-pt));
}

.input-abstract-label,
.input-abstract-hint {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.required-red:after,
.required-blue:after {
  content: '*';
  position: absolute;
  color: var(--color-red);
  top: 0;
  padding-left: 0.5em;
}

.required-blue:after {
  color: var(--color-shark);
}

.input-component-wrapper input:not([type='checkbox']),
input.text-input-field,
.input-like-string,
.textarea-like-string {
  width: 100%;
  height: calc(5 * var(--size-one-pt));
  line-height: calc(5 * var(--size-one-pt) - 2 * var(--size-thin-line));
  padding: 0 var(--size-one-pt);
  background-color: white;
  border-radius: var(--size-half-pt);
  border: var(--size-thin-line) solid var(--color-black-100);
  outline: none;
}

.input-like-string,
.textarea-like-string {
  display: inline-block;
  padding: 0.9rem var(--indent-standard);
  line-height: 2rem;
  height: 4rem;
}

.textarea-like-string {
  overflow: auto;
  height: auto;
  display: inline-block;
  white-space: pre-line;
}

.input-component-wrapper:not(:focus-within):hover input:not([disabled]) {
  border-color: var(--color-hit-gray);
}

.input-component-wrapper input[disabled],
input.text-input-field[disabled],
.input-like-string.disabled,
.textarea-like-string.disabled {
  color: var(--color-oslo-gray);
  background: var(--color-alabaster);
  cursor: not-allowed;
}

.input-component-wrapper:not(.with-arrows) input[disabled] {
  padding-right: var(--indent-standard);
}

.input-component-wrapper:focus-within input,
.input-component-wrapper.has-error input,
.input-component-wrapper.has-error:focus-within input,
input.text-input-field:focus-within {
  border-width: 0.2rem;
}
.input-component-wrapper:focus-within input,
.text-input-field:focus-within {
  border-color: var(--color-primary-500);
}

.input-component-wrapper.has-error input {
  border-color: var(--color-cinnabar) !important;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.input-abstract-hint {
  color: var(--color-black-600);
  cursor: default;
  font-size: var(--font-size-body-2);
}

.input-abstract-component .loader {
  position: absolute;
  top: var(--size-one-pt) !important;
  left: var(--size-one-pt) !important;
  width: calc(3 * var(--size-one-pt)) !important;
  height: calc(3 * var(--size-one-pt)) !important;
  border-width: var(--size-half-pt) !important;
}

.input-abstract-component.compact {
  min-height: calc(3 * var(--size-one-pt));
}
.input-abstract-component.compact input:not([type='checkbox']) {
  line-height: calc(3 * var(--size-one-pt) - 2 * var(--size-thin-line));
  height: calc(3 * var(--size-one-pt));
}
