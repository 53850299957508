.loader {
  border-radius: 100%;
  display: block;
  border-color: var(--color-silver-chalice);
  border-style: solid;
  border-width: var(--size-one-pt);
  border-left-color: transparent;
  width: calc(5 * var(--size-one-pt));
  height: calc(5 * var(--size-one-pt));
  animation: load8 1.1s infinite linear;
}

.loader.small {
  width: calc(3 * var(--size-one-pt));
  height: calc(3 * var(--size-one-pt));
  border-width: var(--size-half-pt);
}

.loader.large {
  width: calc(7 * var(--size-one-pt));
  height: calc(7 * var(--size-one-pt));
  border-width: calc(1.5 * var(--size-one-pt));
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
