.quotes-table-status {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.quotes-table-status > span {
  max-width: 90%;
  text-align: left;
  white-space: nowrap;
}

.quotes-table-status > a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.quotes-table-status_open,
.quotes-table-status_closed,
.quotes-table-status_wrong,
.quotes-table-status_finalized,
.quotes-table-status_submitted,
.quotes-table-status_submitting {
  padding-left: 2rem;
  position: relative;
}

.quotes-table-status_open:before,
.quotes-table-status_closed:before,
.quotes-table-status_wrong:before,
.quotes-table-status_finalized:before,
.quotes-table-status_submitted:before,
.quotes-table-status_submitting:before {
  background-color: #5ab047;
  border-radius: 50%;
  content: '';
  display: block;
  height: calc(2 * var(--size-one-pt));
  width: calc(2 * var(--size-one-pt));
  position: absolute;
  top: var(--size-half-pt);
  left: 0;
}

.quotes-table-status_closed:before {
  background-color: #f76707;
}

.quotes-table-status_wrong:before {
  background-color: var(--color-crimson);
}

.quotes-table-status_submitting:before {
  background-color: var(--color-pacific-blue);
}

.quotes-table-status_finalized:before,
.quotes-table-status_submitted:before {
  background-color: var(--color-vivid-violet);
}
