#addendums-section {
  margin-top: calc(3 * var(--size-one-pt));
}

#addendums-section .addendums-section-content .addendums-block {
  display: grid;
  grid-auto-columns: 1fr 1fr;
  grid-auto-flow: column;
  grid-column-gap: calc(5 * var(--size-one-pt));
}

#addendums-section .right-section div.input-abstract-component:last-of-type {
  margin-bottom: 6rem;
}
