.card-block {
  margin: 0;
  height: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-black-100);
  border-radius: var(--size-half-pt);
  background-color: var(--color-black-050);
}

.card-block > div:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-block.collapse-block {
  height: auto;
  border-radius: var(--radius-light);
}

.card-block.collapse-block > .card-content {
  display: none;
}

.card-header {
  display: flex;
  margin: 0;
  color: var(--color-black-900);
  height: calc(6 * var(--size-one-pt));
  font-size: calc(2.25 * var(--size-one-pt));
}

.card-header-title {
  font-family: Poppins, 'Open Sans', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 var(--size-one-pt) 0 calc(6 * var(--size-one-pt));
  flex: 1 0 auto;
  text-align: left;
  font-weight: 500;
  cursor: pointer;
  line-height: calc(5 * var(--size-one-pt));
}

.has-error .card-header-title {
  padding-left: calc(8 * var(--size-one-pt));
}

.has-error .card-header-title:before {
  position: absolute;
  content: '!';
  width: calc(2 * var(--size-one-pt));
  text-align: center;
  left: calc(6 * var(--size-one-pt));
  font-size: 2.4rem;
  font-weight: 700;
  color: var(--color-carnation);
}

.card-content {
  padding: calc(2 * var(--size-one-pt));
  flex-grow: 1;
  border-top: none;
  border-bottom-left-radius: var(--size-half-pt);
  border-bottom-right-radius: var(--size-half-pt);
  background-color: white;
}

.card-content-category {
  padding: 0;
}

.card-content.card-content-category {
  border-left: none;
  border-right: none;
}

.card-footer {
  display: flex;
  margin: 0;
  padding: 1.3rem 1.2rem;
  align-items: center;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
  color: var(--color-alabaster);
  background-color: var(--color-alabaster);
  border: 1px solid #ced4da;
  border-top: none;
}

.card-block-expand-btn {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: calc(6 * var(--size-one-pt)) !important;
  height: calc(6 * var(--size-one-pt)) !important;
  padding: 0;
  align-items: center;
  justify-content: center;
  color: var(--color-shark) !important;
  cursor: pointer;
  outline: none;
  background-color: transparent !important;
}

.card-block.collapse-block .card-block-expand-btn {
  transform: rotate(180deg);
}

.card-block-expand-btn:focus-visible {
  border-radius: 50%;
  background-color: var(--color-black-050) !important;
  border-color: var(--color-black-300);
  border-width: 2px;
}

.grand-total-block {
  display: flex;
  flex: 0 1 auto;
  margin-left: auto;
  padding-right: 1em;
  align-items: center;
  font-size: var(var(--font-size-body-1));
  font-weight: 600;
  overflow: hidden;
}

.grand-total-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
