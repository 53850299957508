.validate-email-wrapper {
  grid-auto-columns: 1.5fr;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  position: relative;
  display: grid;
  width: 100%;
  align-items: center;
  grid-gap: var(--indent-standard);
}

.validate-email-wrapper button {
  width: fit-content;
}

.validation-status {
  display: grid;
  grid-auto-flow: column;
  width: fit-content;
  align-items: center;
}

.validation-status .icon {
  font-size: calc(var(--size-one-pt) * 3);
}
.validation-status .icon-check {
  color: var(--color-chateau-green);
}
.validation-status .icon-error.risky {
  color: var(--color-buttercup);
}
.validation-status .icon-error.invalid {
  color: var(--color-cinnabar);
}

.validation-status .validation-status-text {
  margin-left: var(--size-half-pt);
  font-size: var(--font-size-body-2);
}
