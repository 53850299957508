.rocket-emblem {
  text-align: center;
  color: var(--color-whisper);
}
.rocket-emblem > img {
  width: 100%;
  max-width: calc(24 * var(--size-one-pt));
  height: auto;
  display: block;
  margin: 0 auto;
}
.rocket-emblem > h2 {
  font-weight: 700;
}
