.header-center-block {
  position: fixed;
  display: grid;
  top: 0;
  height: calc(6 * var(--size-one-pt));
  font-size: var(--font-size-body-2);
  color: var(--color-whisper);
  z-index: 1100;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  align-items: center;
}

.header-center-block .header-quote-info {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: var(--size-one-pt);
  padding: 0 var(--size-one-pt);
  align-items: center;
  background: var(--color-royal-blue);
  border-radius: var(--size-half-pt);
  height: calc(4 * var(--size-one-pt));
  overflow: hidden;
}

.header-controls .quote-history-wrapper,
.header-controls .signature-history-wrapper,
.header-controls .checkpoints-btn-wrapper {
  display: flex;
}

.header-controls {
  height: calc(6 * var(--size-one-pt));
  position: fixed;
  top: 0;
  left: calc(6 * var(--size-one-pt));
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 0;
  z-index: 1199;
  align-items: center;
}

.header-controls > div > button {
  padding: 0 var(--size-one-pt);
  border-left-style: none;
}

.toolbar-actions-component {
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: var(--size-one-pt);
  grid-template-columns: min-content;
  width: fit-content;
  padding: 0 calc(2 * var(--size-one-pt));
}

.toolbar-actions-component.right-action-bar {
  justify-self: end;
}

@media all and (max-width: 1200px) {
  .toolbar-actions-component {
    order: 2;
    padding-bottom: var(--indent-high);
  }
}

.header-controls button:not(:disabled):hover,
.header-drawer-button button:hover {
  background-color: rgba(255, 255, 255, 0.04) !important;
}

.header-controls .sections-navigation .navigation-list {
  position: absolute;
  display: flex;
  top: calc(7 * var(--size-one-pt));
  flex-direction: column;
  background-color: white;
  z-index: 3;
  border-radius: var(--size-half-pt);
  box-shadow: 0 0.4rem 1.6rem rgba(33, 37, 41, 0.24);
}

.header-controls .sections-navigation .navigation-list:before {
  display: block;
  content: '';
  position: absolute;
  top: calc(-2 * var(--size-one-pt));
  left: var(--size-one-pt);
  border-color: transparent transparent white transparent;
  border-width: var(--size-one-pt);
  border-style: solid;
}

.header-controls .nav-item-btn {
  width: 100%;
  font-weight: 400;
  text-align: left;
  white-space: nowrap;
  background-color: transparent !important;
  border-color: transparent !important;
  color: var(--color-shark) !important;
  border-radius: 0;
  padding: var(--size-one-pt) calc(2 * var(--size-one-pt));
  cursor: pointer;
}

.header-controls .nav-item-btn:hover,
.header-controls .nav-item-btn:focus {
  background-image: none;
  background-color: var(--color-section-header-bg) !important;
}

.header-controls .nav-item-btn:focus-visible {
  outline: none;
}

.header-controls .nav-item-btn * {
  pointer-events: none;
}

.header-controls button:disabled,
.header-controls button.disabled {
  color: var(--color-primary-800) !important;
}

.close-navigation-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: unset;
  z-index: 2;
}
