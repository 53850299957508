.date-picker-component {
  display: flex;
  align-items: center;
}

.date-picker-component .icon-calendar {
  position: absolute;
  left: 1px;
  display: flex;
  height: calc(5 * var(--size-one-pt) - 2 * var(--size-thin-line));
  padding: var(--indent-standard);
  align-items: center;
  justify-content: center;
  font-size: 1.7em;
  color: var(--color-oslo-gray);
  border-radius: var(--radius-light) 0 0 var(--radius-light);
  pointer-events: none;
}

.date-picker-component.has-error .icon-calendar {
  background-color: var(--color-cinnabar);
  border-color: var(--color-cinnabar);
  color: var(--color-alabaster);
}

.date-picker-component:not(:focus-within):hover input:not(:disabled) ~ .icon-calendar {
  border-color: var(--color-hit-gray);
}

.date-picker-component:focus-within .icon-calendar {
  left: 0.2rem;
  height: calc(100% - 0.4rem);
  border-radius: 0;
}

.date-picker-component .unset-btn {
  position: absolute;
  display: none;
  right: 0.5em;
  width: 1.5em;
  height: 1.5em;
  padding: 0;
  margin: 0;
  background: none;
  border-radius: 50%;
  color: var(--color-hit-gray);
}

.date-picker-component.open .unset-btn {
  display: block;
}

.date-picker-component.open .unset-btn > .icon-close {
  margin-left: -1px;
}

.date-picker-component.open .unset-btn:focus {
  background-color: rgba(0, 0, 0, 0.2);
}

.date-picker-component .unset-btn:not([disabled]):hover,
.date-picker-component.open .unset-btn:focus {
  color: var(--color-nevada);
}

.date-picker-component input {
  padding-left: 5rem !important;
  min-width: 25rem;
}

.calendar-wrapper {
  position: absolute;
  display: none;
  top: 4.8rem;
  width: 31.4rem;
  padding: calc(2 * var(--size-one-pt));
  font-size: var(--font-size-body-1);
  background-color: var(--color-white);
  border-radius: var(--radius-light);
  border: 1px solid var(--color-ghost);
  z-index: 5;
}

.date-picker-component.open .calendar-wrapper {
  display: block;
}

.error-design-date {
  background: #e03131;
  position: absolute;
  right: 50%;
  top: 4.2rem;
  z-index: 1;
  color: white;
  padding: 0.5rem 0.7rem;
  font-size: 1.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50%;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.ictp-parameters .input-abstract-label {
  font-weight: 600;
}
