.pagination-bar {
  align-items: center;
  bottom: calc(4 * var(--size-one-pt));
  display: flex;
  justify-content: center;
  left: 0;
  padding: 0 2.4rem;
  position: fixed;
  text-align: center;
  width: 100%;
}
