.lease-option-section .card-content {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-auto-rows: 1fr;
  grid-gap: var(--indent-standard);
  align-items: center;
}
.lease-option-section .card-content .input-abstract-component {
  margin: 0;
}
