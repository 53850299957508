@import './palette.css';

/* base primary secondary tertiary critical */
/* blue green   yellow?   white    red */

:root {
  /* Base */
  --button-base-background-color: var(--color-cerulean-blue);
  --button-base-border-color: transparent;
  --button-base-color: white;

  --button-base-hover-background-color: var(--color-cerulean-blue-hover);
  --button-base-hover-border-color: transparent;
  --button-base-hover-color: white;

  --button-base-focus-background-color: var(--color-cerulean-blue-focus);
  --button-base-focus-border-color: transparent;
  --button-base-focus-color: white;

  --button-base-disabled-background-color: var(--color-cerulean-blue-disabled);
  --button-base-disabled-border-color: transparent;
  --button-base-disabled-color: var(--color-blue-haze);

  /* Primary */
  --button-primary-background-color: var(--color-chateau-green);
  --button-primary-border-color: transparent;
  --button-primary-color: white;

  --button-primary-hover-background-color: var(--color-chateau-green-hover);
  --button-primary-hover-border-color: transparent;
  --button-primary-hover-color: white;

  --button-primary-focus-background-color: var(--color-chateau-green-focus);
  --button-primary-focus-border-color: transparent;
  --button-primary-focus-color: white;

  --button-primary-disabled-background-color: var(--color-chateau-green-disabled);
  --button-primary-disabled-border-color: transparent;
  --button-primary-disabled-color: var(--color-powder-ash);

  /* Secondary */
  --button-secondary-background-color: rgba(0, 0, 0, 0);
  --button-secondary-border-color: var(--button-base-color);
  --button-secondary-color: var(--button-base-color);

  --button-secondary-hover-background-color: rgba(0, 0, 0, 0.2);
  --button-secondary-hover-border-color: var(--button-base-color);
  --button-secondary-hover-color: var(--button-base-color);

  --button-secondary-focus-background-color: rgba(0, 0, 0, 0.3);
  --button-secondary-focus-border-color: var(--button-base-color);
  --button-secondary-focus-color: var(--button-base-color);

  --button-secondary-disabled-background-color: rgba(0, 0, 0, 0.1);
  --button-secondary-disabled-border-color: var(--color-powder-ash);
  --button-secondary-disabled-color: var(--color-powder-ash);

  /* Tertiary */
  --button-tertiary-background-color: white;
  --button-tertiary-border-color: var(--color-alto);
  --button-tertiary-color: var(--color-cerulean-blue);

  --button-tertiary-hover-background-color: var(--color-black-squeeze);
  --button-tertiary-hover-border-color: var(--color-alto);
  --button-tertiary-hover-color: var(--color-cerulean-blue);

  --button-tertiary-focus-background-color: var(--color-link-water);
  --button-tertiary-focus-border-color: var(--color-alto);
  --button-tertiary-focus-color: var(--color-cerulean-blue);

  --button-tertiary-disabled-background-color: white;
  --button-tertiary-disabled-border-color: var(--color-alto);
  --button-tertiary-disabled-color: var(--color-silver-chalice);

  /* Text button */
  --button-text-background-color: transparent;
  --button-text-border-color: transparent;
  --button-text-color: var(--color-cerulean-blue);

  --button-text-hover-background-color: transparent;
  --button-text-hover-border-color: var(--color-alto);
  --button-text-hover-color: var(--color-cerulean-blue);

  --button-text-focus-background-color: transparent;
  --button-text-focus-border-color: var(--color-primary-100);
  --button-text-focus-color: var(--color-cerulean-blue);

  --button-text-disabled-background-color: transparent;
  --button-text-disabled-border-color: transparent;
  --button-text-disabled-color: var(--color-silver-chalice);

  /* Critical */
  --button-critical-background-color: var(--color-cinnabar);
  --button-critical-border-color: transparent;
  --button-critical-color: white;

  --button-critical-hover-background-color: var(--color-cinnabar-hover);
  --button-critical-hover-border-color: transparent;
  --button-critical-hover-color: white;

  --button-critical-focus-background-color: var(--color-cinnabar-focus);
  --button-critical-focus-border-color: transparent;
  --button-critical-focus-color: white;

  --button-critical-disabled-background-color: var(--color-cinnabar-disabled);
  --button-critical-disabled-border-color: transparent;
  --button-critical-disabled-color: #d3c5c5;
}
