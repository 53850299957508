.quote-devices-dialog-content {
  display: flex;
  padding: 0;
  flex-direction: column;
  min-height: 60vh;
}

.quote-devices-dialog-actions button {
  width: auto;
  border-radius: var(--radius-light);
}

.empty-dialog-hint {
  text-align: center;
  font-size: var(--font-size-body-1);
  line-height: 2rem;
  font-weight: 600;
}

.empty-dialog-hint a {
  text-decoration: none;
}
