.solution-type .radio-input-group {
  justify-content: flex-end;
  align-items: center;
}

.solution-type .radio-input-group input {
  margin-top: 0;
}

.solution-type .radio-input-group label:not(:last-child) {
  margin-right: 3rem;
}
