@import url('./line-items-catalog.css');

.add-line-items-dialog-content-frame {
  padding: 0;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: min-content min-content 1fr;
  max-height: 75vh;
}

.add-line-items-dialog-filters {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 2fr calc(27 * var(--size-one-pt));
  grid-gap: var(--size-one-pt);
  padding: var(--size-one-pt);
  border-bottom: var(--size-thin-line) solid var(--color-black-200);
}

.add-line-items-dialog-filters > .filter-item .input-abstract-component {
  margin: 0;
  z-index: 50;
}
.add-line-items-dialog-filters > .filter-item.group-by,
.add-line-items-dialog-filters > .filter-item.order-by {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content 1fr;
  grid-gap: var(--size-one-pt);
  align-items: center;
}
.add-line-items-dialog-filters > .filter-item.group-by > label,
.add-line-items-dialog-filters > .filter-item.order-by > label {
  white-space: nowrap;
}

.add-line-items-dialog .sorting-bar {
  padding-left: calc(9 * var(--size-one-pt));
  padding-right: calc(2 * var(--size-one-pt));
  grid-gap: var(--size-one-pt);
  grid-template-columns: 1fr calc(12 * var(--size-one-pt)) calc(5 * var(--size-one-pt));
  background-color: var(--color-black-050);
}

.line-items-catalog-wrapper {
  overflow: auto;
}
.line-items-catalog-wrapper::-webkit-scrollbar {
  width: var(--size-one-pt);
}
.line-items-catalog-wrapper::-webkit-scrollbar-track {
  background-color: var(--color-black-050);
}
.line-items-catalog-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--color-black-500);
}
