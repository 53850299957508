.locations-tab-component {
  position: sticky;
  top: calc(16 * var(--size-one-pt));
  display: grid;
  margin: 0 calc(-1 * var(--size-one-pt)) calc(2 * var(--size-one-pt)) calc(-1 * var(--size-one-pt));
  padding: var(--size-one-pt) calc(1 * var(--size-one-pt)) 0 calc(3 * var(--size-one-pt));
  grid-auto-columns: auto;
  grid-auto-flow: column;
  grid-gap: var(--size-half-pt);
  justify-content: start;
  background-color: var(--color-body-bg);
  border-bottom: var(--size-half-pt) solid var(--color-primary-500);
  z-index: 4;
  align-items: end;
}

.locations-tabs-block {
  display: grid;
  grid-auto-flow: column;
  overflow: hidden;
  align-items: end;
}

.locations-tabs-block .active-tab,
.locations-tabs-block .active-tab:hover,
.locations-tabs-block .active-tab:focus {
  background-color: var(--color-primary-500);
}

.locations-scroll-btn {
  display: none;
  z-index: 2;
}

.locations-tab-component.show-arrows .locations-scroll-btn {
  display: inline-grid;
}

.locations-scroll-btn.btn-left {
  margin-left: var(--size-half-pt);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0.2rem 0.2rem 0.6rem 0 rgba(74, 74, 74, 0.5);
}

.locations-scroll-btn.btn-right {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  box-shadow: -0.2rem 0.2rem 0.5rem 0 rgba(74, 74, 74, 0.5);
}

.locations-tab-wrapper {
  position: relative;
  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  grid-gap: var(--size-half-pt);
  scroll-behavior: smooth;
  overflow: auto;
  margin-left: var(--size-half-pt);
  align-items: end;
}

.locations-tab-component.show-arrows .locations-tab-wrapper {
  padding: 0 var(--size-one-pt);
  margin-left: 0;
}

.locations-tab-wrapper::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
}

.locations-tab-actions {
  display: grid;
  grid-auto-flow: column;
  grid-gap: var(--size-half-pt);
  align-items: center;
}

.location-tab {
  max-width: 13em;
  flex-shrink: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.location-tab,
.locations-tab-component .add-location,
.locations-tab-component .locations-select-btn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.location-tab.order-tab {
  max-width: none;
}

.tab-wrapper {
  position: relative;
}

.locations-tabs-block > .tab-wrapper {
  margin-left: 0;
}

.tab-wrapper.warning-tab::after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: var(--color-cinnabar);
  transform: translate(25%, -25%);
  z-index: 2;
}
