.ictpps-table > thead > tr > th:first-child,
.ictpps-table > tbody > tr > th:first-child,
.ictpps-table > tbody > tr > td:first-child {
  width: auto;
  max-width: unset;
}

.ictpps-details {
  width: 80vw;
}

.ictpps-details .s2s-modal-content {
  max-height: 70vh;
}

.ictpps-table-row th {
  background-color: unset !important;
  box-shadow: unset !important;
}

.ictpps-table .ictpps-value-cell {
  min-width: calc(10 * var(--size-one-pt));
  max-width: calc(10 * var(--size-one-pt));
}

@media (max-width: 780px) {
  .ictpps-details {
    width: 90vw;
  }
}

@media (max-width: 1400px) {
  .ictpps-table > thead > tr > th:first-child,
  .ictpps-table > tbody > tr > th:first-child,
  .ictpps-table > tbody > tr > td:first-child {
    width: calc(26 * var(--size-one-pt));
    max-width: calc(26 * var(--size-one-pt));
  }

  .ictpps-table .ictpps-value-cell {
    min-width: calc(5 * var(--size-one-pt));
    max-width: calc(5 * var(--size-one-pt)) !important;
  }
}
