.sorting-bar {
  --sorting-arrows-box-width: calc(2 * var(--size-one-pt));
  --sorting-bar-height: calc(4 * var(--size-one-pt));

  margin: 0;
  padding: 0;
  display: grid;
  grid-auto-flow: column;
  list-style: none;
}

.sorting-bar > .option {
  position: relative;
  cursor: pointer;
  padding: 0;
  font-size: var(--font-size-body-2);
  font-weight: 600;
  line-height: var(--sorting-bar-height);
}

.sorting-bar > .option:before,
.sorting-bar > .option:after {
  font-family: icomoon;
  display: none;
  align-items: center;
  justify-content: center;
  width: var(--sorting-arrows-box-width);
  position: absolute;
  color: var(--color-black-500);
  line-height: var(--size-one-pt);
  align-content: center;
  top: 0;
  bottom: 0;
}

.sorting-bar > .option:before {
  align-items: flex-end;
  bottom: 50%;
  content: '\e912';
}
.sorting-bar > .option:after {
  align-items: flex-start;
  top: 50%;
  content: '\e913';
}

.sorting-bar > .option.asc:before,
.sorting-bar > .option.desc:after,
.sorting-bar > .option:hover:before,
.sorting-bar > .option:hover:after {
  display: flex;
}

.sorting-bar > .option.asc,
.sorting-bar > .option.asc:before,
.sorting-bar > .option.desc,
.sorting-bar > .option.desc:after {
  color: var(--color-primary-500);
}

.sorting-bar.align-left > .option,
.sorting-bar > .option.align-left {
  text-align: left;
  padding-left: var(--sorting-arrows-box-width);
}
.sorting-bar.align-left > .option:before,
.sorting-bar.align-left > .option:after,
.sorting-bar > .option.align-left:before,
.sorting-bar > .option.align-left:after {
  left: 0;
  right: auto;
}

.sorting-bar.align-right > .option,
.sorting-bar > .option.align-right {
  text-align: right;
  padding-right: var(--sorting-arrows-box-width);
}
.sorting-bar.align-right > .option:before,
.sorting-bar.align-right > .option:after,
.sorting-bar > .option.align-right:before,
.sorting-bar > .option.align-right:after {
  left: auto;
  right: 0;
}

.sorting-bar > .option.inactive {
  padding-right: 0;
  cursor: default;
}
.sorting-bar > .option.inactive:before,
.sorting-bar > .option.inactive:after {
  display: none;
}
