.about-modal__content-link,
.about-modal__content-copyright {
  margin-top: 1rem;
}

.about-modal {
  width: max-content;
}

.about-modal .default-confirm-modal-actions {
  margin-top: 2rem;
  grid-auto-columns: initial;
}

.about-modal .default-confirm-modal-actions button {
  width: min-content;
}
