.mad-max-upfront.input-abstract-component,
.spiff-amount.input-abstract-component {
  margin-top: 1.2rem;
  grid-auto-columns: auto;
}
.mad-max-upfront.input-abstract-component .input-abstract-component {
  margin: 0;
}

.mad-pool-override.input-abstract-component {
  margin-top: 1.2rem;
}
