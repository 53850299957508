@import 'sangoma-palette.css';

/* http://chir.ag/projects/name-that-color/ been used to pick color names */
/* https://www.color-blindness.com/color-name-hue/ been used to pick color names */
:root {
  --color-white: #fff;
  --color-black-squeeze: #f6fafc;
  --color-whisper: #f5f6fa;
  --color-alabaster: #f8f9fa;
  --color-iron: #e9e9ea;
  --color-link-water: #e6eff7;
  --color-blue-haze: #dadff2;
  --color-athens-gray: #ebedf5;
  --color-alto: #e0e0e0;
  --color-porcelain: #e9ecef;
  --color-silver-chalice: #9e9e9e;
  --color-ghost: #ced4da;
  --color-quill-gray: #cecece;
  --color-silver-sand: #c6cacc;
  --color-mischka: #dfe1e6;
  --color-section-header-bg: #f4f5f7;
  --color-mystic: #e0e7eb;
  --color-solitude: #f5f6fa;
  --color-white-smoke: #f2f2f2;
  --color-hit-gray: #adb5bd;
  --color-oslo-gray: #868e96;
  --color-slate-grey: #748290;
  --color-nevada: #5e6366;
  --color-outer-space: #343a40;
  --color-shark: #20272d;

  --color-zircon: #f0f5ff;
  --color-lilly-white: #e6efff;
  --color-cornflower-blue: #97a7e2;
  --color-java: #15aabf;
  --color-surfie-green: #0b7285;
  --color-pacific-blue: #0091cc;
  --color-cerulean-blue: #304dc5;
  --color-bahama-blue: #005496;
  --color-cerulean-blue-hover: #325bc4;
  --color-royal-blue: #3b5ff2;
  --color-curious-blue: #3e64ff;
  --color-matisse: #1b6ec2;
  --color-dodger-blue: #367bf5;
  --color-cornflower-royal-blue: #4c6ef5;
  --color-persian: #364fc7;
  --color-cerulean-blue-focus: #4567c9;
  --color-blue-marguerite: #6376c5;
  --color-blue-violet: #596bb3;
  --color-kashmir-blue: #4d5b99;
  --color-cerulean-blue-disabled: #4e61a7;
  --color-east-bay: #404c80;
  --color-midnight-blue: #1f3280;
  --color-tarawera: #262e4d;

  --color-chateau-green: #37b24d;
  --color-chateau-green-hover: #319f45;
  --color-chateau-green-focus: #3b954b;
  --color-chateau-green-disabled: #559461;
  --color-powder-ash: #bacaba;
  --color-granny-apple: #dcf7e3;

  --color-carnation: #fa5252;
  --color-crimson: #e03131;
  --color-cinnabar: #ea3d2f;
  --color-cinnabar-hover: #e82718;
  --color-cinnabar-focus: #d22215;
  --color-cinnabar-disabled: #cc564d;
  --color-red: #ff0000;

  --color-vivid-violet: #9c36b5;
  --color-studio: #862e9c;
  --color-medium-orchid: #be4bdb;

  --color-yellow-sea: #fab005;
  --color-buttercup: #f3aa18;
  --color-orange-peel: #ff9f00;
  --color-mango-tango: #e67700;

  --color-border: var(--color-ghost);
  --color-body-bg: white;
}
