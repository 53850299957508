.field-clear-btn {
  position: absolute;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: var(--size-half-pt);
  top: 50%;
  margin-top: calc(-2 * var(--size-one-pt));
  right: var(--size-half-pt);
  padding: 0 var(--size-one-pt);
}

.field-clear-btn .icon-close {
  margin-left: var(--indent-standard);
}

.field-clear-btn .clear-btn-text {
  text-transform: lowercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.field-clear-btn:focus {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 100%);
}
