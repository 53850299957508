.iconButton-tooltip-wrapper {
  position: absolute;
  display: none;
  flex-flow: row wrap;
  padding: 0;
  top: 100%;
  color: var(--color-alabaster);
  background-color: transparent;
  z-index: 999;
  font-size: var(--font-size-body-2) !important;
  max-width: 80vw;
}

.iconButton-tooltip-wrapper > .iconButton-tooltip {
  display: block;
  position: relative;
  width: max-content;
  padding: var(--size-one-pt) calc(2 * var(--size-one-pt));
  text-align: left;
  color: var(--color-white);
  white-space: pre-line;
  margin: var(--size-one-pt) 0 0 0;
  opacity: 1;
}

.iconButton-tooltip-wrapper .iconButton-tooltip:before {
  content: '';
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  opacity: 0.95;
  border-radius: var(--size-one-pt);
  background-color: var(--color-black-900);
}

.iconButton-tooltip-wrapper .iconButton-tooltip::after {
  content: '';
  position: absolute;
  top: calc(-2 * var(--size-one-pt));
  border-width: var(--size-one-pt);
  border-style: solid;
  border-color: transparent transparent var(--color-black-900) transparent;
  opacity: 0.95;
}

.iconButton-tooltip-wrapper > .iconButton-tooltip > * {
  position: relative;
}

.iconButton-tooltip-wrapper.right-align-tooltip {
  left: unset;
  right: 0;
}
.iconButton-tooltip-wrapper.right-align-tooltip .iconButton-tooltip {
  justify-self: end;
}
.iconButton-tooltip-wrapper.right-align-tooltip .iconButton-tooltip:after {
  left: unset;
  right: var(--size-one-pt);
}

.iconButton-tooltip-wrapper.left-align-tooltip {
  left: 0;
  right: unset;
}

.iconButton-tooltip-wrapper.left-align-tooltip .iconButton-tooltip {
  justify-self: start;
}
.iconButton-tooltip-wrapper.left-align-tooltip .iconButton-tooltip:after {
  left: var(--size-one-pt);
  right: unset;
}

.iconButton-tooltip-wrapper.top-align {
  top: auto;
  bottom: 100%;
  flex-flow: wrap-reverse;
}

.iconButton-tooltip-wrapper.top-align .iconButton-tooltip {
  margin-bottom: var(--size-one-pt);
  margin-top: 0;
}

.iconButton-tooltip-wrapper.top-align .iconButton-tooltip::after {
  bottom: calc(-2 * var(--size-one-pt));
  top: auto;
  transform: rotate(180deg);
}

@media (max-width: 1200px) {
  .iconButton-tooltip-wrapper.right-align-tooltip {
    left: 0;
    right: unset;
  }
  .iconButton-tooltip-wrapper.right-align-tooltip .iconButton-tooltip:after {
    left: var(--size-one-pt);
    right: unset;
  }
}

@media (max-width: 1100px) {
  .iconButton-tooltip-wrapper .iconButton-tooltip {
    width: 60vw;
  }
}

@media (max-width: 700px) {
  .iconButton-tooltip-wrapper .iconButton-tooltip {
    width: 50vw;
  }
}
