@media all and (min-width: 1023px) {
  .ictp-parameters {
    width: 55%;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-column-gap: 3rem;
  }
}

@media (max-width: 1600px) {
  .ictp-parameters {
    width: 65%;
  }
}

@media (max-width: 1500px) {
  .ictp-parameters {
    width: 70%;
  }
}

@media (max-width: 1300px) {
  .ictp-parameters {
    width: 80%;
  }
}
@media (max-width: 1100px) {
  .ictp-parameters {
    width: 90%;
  }
}

@media (max-width: 1023px) and (min-width: 720px), (min-width: 1440px) {
  .ictp-parameters .input-abstract-component {
    grid-auto-flow: row;
    width: 100%;
  }
}

.ictp-parameters {
  padding: 1.6rem;
}

.ictpps-btn {
  margin-top: 2.5rem;
  height: 3.8rem;
  white-space: nowrap;
}
