.li-category {
  background-color: var(--color-white);
  box-sizing: border-box;
  padding: calc(2 * var(--size-one-pt)) 0 calc(1 * var(--size-one-pt));
  font-size: var(var(--font-size-body-1));
  position: relative;
  border-top: var(--size-thin-line) solid var(--color-border);
  border-bottom-left-radius: var(--size-half-pt);
  border-bottom-right-radius: var(--size-half-pt);
}

.li-category:first-child {
  border-top-style: none;
}

.li-category > .headers {
  display: flex;
  justify-content: flex-end;
  padding: 0 4.4rem 0 0.8rem;
}

.li-category > .headers > .cell {
  width: 10%;
  height: 4rem;
  font-weight: 600;
  text-align: right;
  padding: 0 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.li-category > .headers > .cell.quantity,
.line-item > .cell.quantity {
  width: 7%;
}

.li-category > .headers > .cell:first-child {
  flex-grow: 1;
  text-align: left;
  display: flex;
  margin-right: auto;
  padding-left: 0;
  max-width: 30%;
  overflow: visible;
  text-overflow: initial;
}

.li-category > .headers > .cell.partnerProductName {
  flex-grow: 1;
}

.li-category > .headers > .cell:first-child > .category-name {
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
}

.li-category > .headers > .cell:first-child > .category-name.has-error {
  position: relative;
  padding-left: calc(2 * var(--size-one-pt));
}

.li-category > .headers > .cell:first-child > .category-name.has-error:before {
  position: absolute;
  content: '!';
  width: calc(2 * var(--size-one-pt));
  text-align: center;
  left: 0;
  font-size: 2.4rem;
  font-weight: 700;
  color: var(--color-carnation);
}

.li-category .no-line-items-hint {
  font-weight: normal;
  line-height: 1.15em;
  letter-spacing: 0.02rem;
  color: var(--color-black-600);
  text-overflow: ellipsis;
  overflow: hidden;
}

.line-items > .no-line-items-hint {
  padding: 1.5em 0 1em 2.5em;
  font-size: var(var(--font-size-body-1));
}

.add-line-item-btn {
  color: var(--color-cerulean-blue);
  font-weight: 600;
  font-size: 1.75em;
}

.wrapper-add-line-item-btn {
  position: absolute;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content auto;
  left: var(--size-one-pt);
  align-items: center;
}

.wrapper-add-line-item-btn .category-hint,
.wrapper-add-line-item-btn > button + button {
  margin-left: var(--size-one-pt);
}
.wrapper-add-line-item-btn .category-hint {
  color: var(--color-silver-chalice);
}

.wrapper-add-line-item-btn .phone-link-wrapper,
.wrapper-add-line-item-btn .starbox-sizing-link-wrapper {
  margin-left: var(--size-one-pt);
  max-width: 70rem;
  font-size: var(--font-size-body-2);
  line-height: calc(2 * var(--size-one-pt));
}

.wrapper-add-line-item-btn .phone-link-wrapper span,
.wrapper-add-line-item-btn .phone-link-wrapper a {
  display: block;
}

.wrapper-add-line-item-btn .starbox-sizing-link-wrapper .starbox-sizing-link-hint,
.wrapper-add-line-item-btn .phone-link-wrapper .phone-link-hint {
  color: var(--color-black-600);
}

@media (max-width: 1200px) {
  .wrapper-add-line-item-btn {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .wrapper-add-line-item-btn {
    padding-left: var(--size-one-pt);
  }

  .wrapper-add-line-item-btn button {
    padding: 0;
  }

  .wrapper-add-line-item-btn button span {
    padding-left: var(--size-one-pt);
    padding-right: var(--size-half-pt);
  }
}

/***** MOBILE UI *****/

@media all and (max-width: 570px) {
  .li-category > .headers > .cell:first-child {
    max-width: 100%;
  }
  .li-category > .headers > .cell > :not(.category-name) {
    display: none;
  }
}
