.number-input-component input {
  text-align: right;
}

.number-input-component.with-arrows input {
  padding-right: calc(4 * var(--size-one-pt));
}

.number-input-component input,
.number-input-component input:hover,
.number-input-component input:focus {
  -moz-appearance: textfield;
}

.number-input-field + .number-input-btn-wrapper {
  display: grid;
  grid-template-rows: 1fr 1fr;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.number-input-field + .number-input-btn-wrapper > button {
  padding: 0;
  margin: 0;
  width: calc(3 * var(--size-one-pt));
  height: 100%;
  display: block;
}
.number-input-field + .number-input-btn-wrapper > button > span {
  display: block;
  font-size: var(--font-size-body-1);
}
