.radio-input-group-component {
  height: inherit;
  margin: 0.5rem 0;
}

.radio-input-group-component > div {
  display: flex;
}

.radio-input-group-component input {
  display: none;
}

.radio-input {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.radio-input:before {
  font-family: 'icomoon' !important;
  content: '\e901';
  font-size: 2rem;
  line-height: 2rem;
  color: var(--color-black-900);
  margin-right: var(--size-one-pt);
}

.radio-input:not(.disabled):hover:before {
  color: var(--color-primary-500);
}

.radio-input-group-component .radio-input.checked:before {
  font-family: 'icomoon' !important;
  content: '\e900';
  color: var(--color-primary-500);
}

.radio-input-group-component input + .disabled.radio-input:before {
  color: var(--color-hit-gray);
}

.radio-input.disabled {
  cursor: not-allowed;
  color: var(--color-oslo-gray);
}
