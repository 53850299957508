.add-locations-modal {
  padding: 0;
}

.add-locations-modal .default-modal-label p {
  padding: 2rem;
  margin: 0;
}

.add-locations-modal .default-confirm-modal-actions {
  padding: 1.5rem 2rem 2rem;
}

.add-locations-modal .default-modal-label {
  margin: 0;
}

.add-locations-table-wrapper {
  min-height: 40vh;
  max-height: 40vh;
  overflow: auto;
  position: relative;
}

.add-locations-table .checkbox-input-abstract {
  margin: 0;
}

.add-locations-table th {
  top: -1px !important;
}

.add-locations-table tr.checked {
  color: var(--color-dodger-blue);
  background-color: var(--color-lilly-white) !important;
}

.add-locations-table tr.existing span {
  cursor: auto;
}

.add-locations-table tr > th:first-of-type,
.add-locations-table tr > td:first-of-type {
  width: 1%;
}

.add-locations-table td > span,
.add-locations-table td > div {
  cursor: pointer;
}

.add-locations-table .checkbox-box .icon-check {
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
  margin-left: 0;
  color: transparent;
}

.add-locations-table input:checked + label .checkbox-box .icon-check {
  color: white;
}

.filter-location {
  overflow: hidden;
  padding: 0 1rem;
  position: relative;
  width: 100%;
}

.filter-location .icon-search {
  display: inline-block;
  font-size: 3.2rem;
  left: 0.6rem;
  position: absolute;
  top: 0.5rem;
  color: var(--color-ghost);
}

.filter-location:focus-within .icon-search {
  color: var(--color-cerulean-blue);
}
