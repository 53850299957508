#mad-slider-anchors {
  height: 0;
  margin: 0 auto;
  position: relative;
  width: 95%;
  top: -19px;
}

/* Set z-index for slider handle to be on top */
.rc-slider.percent-slider .rc-slider-handle {
  z-index: 2;
}

/* Invisible slider anchors */
#mad-slider-anchors .mad-slider-anchor {
  background: transparent;
  border-style: none;
  border-radius: 0;
  cursor: default;
  height: 4px;
  margin: 0 0 0 -1px;
  padding: 0;
  position: absolute;
  width: 2px;
  z-index: 1;
}
#mad-slider-anchors .mad-slider-anchor:disabled {
  background-color: transparent !important;
}
