.quote-history-dialog-content {
  min-height: 70rem;
  max-height: 70rem;
}

@media all and (max-height: 860px) {
  .quote-history-dialog-content {
    min-height: 50rem;
    max-height: 50rem;
  }
}

.quote-history-table > tbody > tr > td > a {
  color: inherit;
  display: block;
}
