.env-badge {
  background: coral;
  padding: 0 var(--size-one-pt);
  font-size: var(--font-size-body-3);
  color: var(--color-whisper);
  text-transform: uppercase;
  width: max-content;
  text-shadow:
    1px 0 0 #804028,
    1px 1px 0 #804028,
    0 1px 0 #804028,
    -1px 1px 0 #804028,
    -1px 0 0 #804028,
    -1px -1px 0 #804028,
    0 -1px 0 #804028,
    1px -1px 0 #804028;
}
