.s2s-modal-component.signature-details-modal {
  min-width: 40vw;
  max-width: 80vw;
  width: auto;
}

.signature-details-modal .s2s-modal-content {
  overflow: auto;
}

.signature-details-title-wrapper {
  line-height: 1.5em;
}

.signature-details-title-header {
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  align-items: center;
  text-transform: none;
  text-align: center;
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 1.5em;
}

.signature-details-title-header > span {
  line-height: 1.5em;
}

.signature-details-title-header > small > span {
  line-height: 1.5em;
  font-weight: 300;
  padding-right: 2.5rem;
}
.signature-details-title-header > small > span:last-of-type {
  padding-right: 0;
}

.signature-details-table-header {
  font-weight: 600;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 1.6rem;
}
.signature-details-table-header .envelope-header {
  margin-left: 13rem;
}
.signature-details-table-header > div {
  border-bottom: 1px solid var(--color-silver-sand);
  padding: 0 0.7rem 1rem;
}
.signature-details-table-header.details-has-pdf,
.signature-details-table-header.has-pdf-context {
  grid-template-columns: 1fr 1fr 1fr;
}
.signature-details-table-header.details-has-pdf.has-pdf-context {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.signature-details-table-body {
  font-weight: 600;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.signature-details-table-body > div {
  padding: 0 0.7rem;
}
.signature-details-table-body.details-has-pdf,
.signature-details-table-body.has-pdf-context {
  grid-template-columns: 1fr 1fr 1fr;
}
.signature-details-table-body.details-has-pdf.has-pdf-context {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.signature-details-table .contents-body .toggle-input-abstract,
.signature-details-table .context-body .toggle-input-abstract {
  margin-bottom: 0;
}
.signature-details-table .contents-body .toggle-input-abstract .input-component-wrapper,
.signature-details-table .context-body .toggle-input-abstract .input-component-wrapper {
  height: initial;
  padding: 0.2rem 0;
}

.envelope-body {
  display: grid;
  grid-auto-rows: min-content;
}

.envelope-body-row {
  display: grid;
  grid-template-columns: 13rem 1fr;
}

.envelope-body-row span:first-child,
.custom-pdf-body-row span:first-child,
.pdf-body-row span:first-child {
  text-align: end;
  margin-right: 1rem;
}

.envelope-body-row:first-of-type span:first-child {
  margin-top: 1.1rem;
}

.envelope-body-row span:last-child {
  padding-right: 2rem;
  font-weight: 400;
  color: var(--color-nevada);
}

.envelope-body-row:first-of-type span:last-child {
  padding-top: 1rem;
}

.envelope-body-row:last-of-type {
  padding-top: 1.5rem;
}

.custom-pdf-body-row,
.pdf-body-row {
  padding-top: 1rem;
}

.custom-pdf-body-row span:last-child,
.pdf-body-row span:last-child {
  font-weight: 400;
  color: var(--color-nevada);
}

.details-pdf-header {
  font-weight: 600;
  font-size: 1.6rem;
}

.signature-details.customer-signer.details-pdf {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 2.4fr 2fr;
}

.signature-details-pdf-section {
  margin-top: 3rem;
}

.signer-email .input-abstract-error {
  left: 0;
  width: 100%;
}

.signer-reassignments .icon {
  cursor: auto;
  margin-left: 0.3rem;
}

.signer-reassignments .icon:hover,
.signer-reassignments div:hover .icon {
  color: var(--color-curious-blue);
}

.signer-reassignments > div {
  position: relative;
  display: flex;
  align-items: center;
}

.signer-reassignments div:hover > .reassignments-tooltip,
.reassignments-tooltip:hover {
  display: grid;
}

.reassignments-tooltip {
  position: absolute;
  display: none;
  bottom: 0;
  right: 0;
  min-width: 100%;
  width: auto;
  padding: 1rem;
  transform: translateX(100%);
  background: var(--color-porcelain);
  border: solid 0.2rem var(--color-curious-blue);
  z-index: 999;
}

.reassignments-tooltip.reverse {
  transform: translateX(-4rem);
}

.reassignments-tooltip > span {
  padding-left: 5rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
}

.reassignments-tooltip-row {
  display: grid;
  grid-auto-columns: 4rem auto;
  grid-auto-flow: column;
  grid-gap: 1rem;
  padding-top: 0.5rem;
}

.reassignments-tooltip > div:not(:last-of-type) .reassignments-tooltip-row:last-of-type {
  border-bottom: 1px solid #000;
  padding-bottom: 1rem;
}

.reassignments-tooltip-row:first-of-type {
  padding-top: 1rem;
}

.reassignments-tooltip-row div:first-of-type {
  text-align: right;
  font-weight: 600;
}

.reassignments-tooltip-row div:last-child {
  white-space: nowrap;
}

.reassignments-count {
  padding-left: 0.5rem;
}

.signature-details-modal .icon-arrow-back {
  font-size: var(--font-size-button-m);
}

.signature-details-signers-table > tbody > tr > td .email-cell,
.signature-details-signers-table > tbody > tr > td .email-cell-editable {
  width: 100%;
}
.signature-details-signers-table > tbody > tr > td .email-cell .email-label {
  overflow: hidden;
  text-overflow: ellipsis;
}

.signature-details-signers-table > tbody > tr > td .email-cell-editable {
  display: grid;
  grid-template-columns: 1fr min-content;
}
