:root {
  --font-size-root: 10px;

  --size-thin-line: 1px;
  --size-quarter-pt: 0.2rem;
  --size-half-pt: 0.4rem;
  --size-one-pt: 0.8rem;

  --font-size-body-1: calc(2 * var(--size-one-pt));
  --font-size-body-2: calc(1.75 * var(--size-one-pt));
  --font-size-body-3: calc(1.5 * var(--size-one-pt));

  --font-size-button-s: var(--font-size-body-3);
  --font-size-button-m: var(--font-size-body-2);
  --font-size-button-l: var(--font-size-body-1);
  --font-size-button-xl: calc(2.5 * var(--size-one-pt));

  --indent-low: var(--size-half-pt);
  --indent-standard: var(--size-one-pt);
  --indent-medium: calc(var(--size-half-pt) * 3);
  --indent-high: calc(var(--size-one-pt) * 2);
  --indent-maximum: calc(var(--size-one-pt) * 4);

  --radius-light: var(--size-half-pt);
}
