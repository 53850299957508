.approval-modal {
  min-width: 60rem;
  overflow: initial;
}

.approval-modal .default-confirm-modal-actions {
  width: 30rem;
  margin-left: auto;
}

.approval-modal textarea {
  resize: none;
  width: 100%;
  height: inherit;
  min-height: 30rem;
  font-size: var(var(--font-size-body-1));
  padding: var(--indent-high);
}

.default-modal-label p:last-of-type:not(:first-of-type) {
  margin: var(--size-one-pt) 0;
  font-size: var(--font-size-body-2);
  line-height: 1.4285em;
}

.default-modal-label .required-red:after {
  position: initial;
  padding-left: var(--size-quarter-pt);
}
