.location-action-bar-component {
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: var(--size-one-pt);
}
.location-action-bar-component > button {
  color: var(--color-cerulean-blue);
  background-color: transparent !important;
}
.location-action-bar-component > button:hover {
  color: var(--color-cerulean-blue-hover);
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.location-action-bar-component > button:focus {
  color: var(--color-cerulean-blue-focus);
}
.location-action-bar-component > button:disabled {
  color: var(--color-cerulean-blue-disabled);
}
.location-action-bar-component > button.delete {
  font-size: calc(2.625 * var(--size-one-pt));
}
