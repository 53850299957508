.select-input {
  --input-height: calc(5 * var(--size-one-pt));
  display: block;
  position: relative;
  text-align: left;
  font-size: var(--font-size-body-2);
}

.select-input.expanded {
  z-index: 10;
}
.select-input > .selected-option-field {
  cursor: default;
  display: block;
  padding: 0 calc(5 * var(--size-one-pt)) 0 var(--size-one-pt);
  height: var(--input-height);
  background-color: white;
  line-height: calc(var(--input-height) - 2 * var(--size-thin-line));
  border-radius: var(--size-half-pt);
  border-width: var(--size-thin-line);
  border-color: var(--color-black-100);
  border-style: solid;
  position: relative;
  /* TODO: Remove overflow hidden once text ellipsis will be added */
  overflow: hidden;
}
.select-input > .selected-option-field:after {
  content: '\e913';
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  position: absolute;
  top: 0;
  right: var(--size-one-pt);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(3.5 * var(--size-one-pt));
  color: var(--color-black-200);
  pointer-events: none;
}

.select-input > .selected-option-field > .selected-option-label,
.select-input > .selected-option-field > .placeholder {
  /* TODO: Make ellipsis overflow work without expanding with of the parent
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  */
}

.select-input > .selected-option-field > .placeholder {
  color: var(--color-black-500);
}

.select-input:focus-within:not(.disabled) > .selected-option-field {
  outline: 2px solid var(--color-primary-500);
  border-color: transparent !important;
}

.select-input > .options {
  list-style: none;
  margin: 0;
  padding: var(--size-half-pt) 0;
  position: absolute;
  top: calc(100% - var(--size-half-pt) - var(--size-thin-line));
  right: 0;
  background-color: white;
  border-width: var(--size-thin-line);
  border-radius: var(--size-half-pt);
  border-style: solid;
  border-color: var(--color-black-100);
  overflow: auto;
  box-shadow: 0 0.4rem 1.6rem rgb(33 37 41 / 24%);
  max-height: 45vh;
  max-width: 50vw;
  min-width: 100%;
}
.select-input > .options > .option {
  margin: 0;
  padding: calc(1.5 * var(--size-one-pt)) var(--size-one-pt);
  line-height: calc(2 * var(--size-one-pt));
}
.select-input > .options > .option.disabled {
  cursor: not-allowed !important;
  background-color: var(--color-alabaster);
  color: var(--color-oslo-gray);
}
.select-input > .options > .option:not(.disabled) {
  cursor: pointer;
}
.select-input > .options > .option.highlight {
  background-color: var(--color-primary-025);
  outline: none;
}

.select-input:hover > .selected-option-field,
.select-input:hover > .options {
  border-color: var(--color-black-200);
}

.select-input.disabled > .selected-option-field {
  background-color: var(--color-alabaster);
  color: var(--color-oslo-gray);
  cursor: not-allowed;
  border-color: var(--color-black-100);
}
.select-input.disabled > .selected-option-field:after {
  color: var(--color-black-100);
}
