.clone-options-modal {
  padding: 0;
  overflow: visible;
}

.clone-options-modal .default-modal-label {
  margin: 0;
}

.clone-options-modal .default-modal-label p {
  padding: 2rem;
  margin: 0;
}

.clone-options-inputs-wrapper {
  width: 50rem;
}

.clone-options-inputs-wrapper > div {
  padding: 0 2rem;
}

.clone-options-industry-type .select-option-list {
  max-height: 35vh;
}

.clone-options-modal .default-confirm-modal-actions {
  padding: 1.5rem 2rem 2rem;
}
