.toolbar-wrapper {
  position: fixed;
  display: flex;
  top: calc(6 * var(--size-one-pt));
  width: 100%;
  min-height: 6.4rem;
  background-color: var(--color-primary-800);
  z-index: 11;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(32, 36, 40, 0.48);
}

.toolbar-wrapper .toolbar {
  display: grid;
  width: 100%;
  grid-auto-columns: 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-auto-flow: column;
  align-items: center;
}

@media all and (max-width: 1200px) {
  .toolbar-wrapper .toolbar {
    grid-auto-flow: row;
    justify-items: start;
    justify-content: center;
  }
}

@media all and (max-width: 570px) {
  .toolbar-wrapper .toolbar {
    padding-left: 0;
  }
}
