.line-items-catalog {
  --nesting-title-height: calc(5 * var(--size-one-pt));
  --indent-border-width: var(--size-quarter-pt);
  --indent-padding: calc(4 * var(--indent-border-width));
  --section-indent-color: var(--color-black-400);
  --category-indent-color: var(--color-black-300);
  --group-indent-color: var(--color-black-200);
  --device-border-color: var(--color-black-100);
}

.line-items-catalog-wrapper {
  overflow: auto;
}
.line-items-catalog ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.line-items-catalog li {
  margin: 0;
  padding: 0;
}
.line-items-catalog ul > li > label {
  display: block;
  font-size: var(--font-size-body-2);
  font-style: italic;
  line-height: calc(var(--nesting-title-height) - 1 * var(--indent-border-width));
  padding-left: var(--indent-padding);
  position: sticky;
  background-color: var(--color-black-050);
  border-width: var(--indent-border-width) 0 var(--indent-border-width) calc(1 * var(--indent-padding));
  border-style: solid none none solid;
}

.line-items-catalog ul > li > ul {
  padding-left: var(--indent-padding);
}

.line-items-catalog ul > li.section {
  background-color: var(--section-indent-color);
}
.line-items-catalog ul > li.section > label {
  border-color: var(--section-indent-color);
  top: 0;
  z-index: 30;
}
.line-items-catalog ul > li.category {
  background-color: var(--category-indent-color);
}
.line-items-catalog ul > li.category > label {
  border-color: var(--category-indent-color);
  top: var(--nesting-title-height);
  z-index: 20;
}
.line-items-catalog ul > li.group {
  background-color: var(--group-indent-color);
}
.line-items-catalog ul > li.group > label {
  border-color: var(--group-indent-color);
  top: calc(2 * var(--nesting-title-height));
  z-index: 10;
}

/* Device row styles */
.line-items-catalog .device {
  padding: var(--size-one-pt);
  background-color: white;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: calc(3 * var(--size-one-pt)) 1fr calc(12 * var(--size-one-pt)) calc(5 * var(--size-one-pt));
  align-items: center;
  grid-gap: var(--size-one-pt);
  border-top: var(--size-thin-line) solid var(--device-border-color);
}

.line-items-catalog .device:first-child {
  border-top: none;
}
.line-items-catalog .device .input-abstract-component {
  margin: 0;
  min-height: 0;
}
.line-items-catalog .device .checkbox-input-component {
  height: calc(3 * var(--size-one-pt));
}
.line-items-catalog .device > .line-item-name {
  width: auto;
}
.line-items-catalog .device > .delaer-net {
  text-align: right;
}
.line-items-catalog .device > .delaer-net.bundle {
  color: var(--color-black-500);
  text-decoration: underline;
}
.line-items-catalog .device > .slick-link {
  font-size: calc(3 * var(--size-one-pt));
  text-align: center;
}
.line-items-catalog .device > .slick-link > a {
  color: var(--color-black-900);
  text-decoration: none;
}

.line-items-catalog .device.is-selected {
  background-color: var(--color-primary-025);
}

.line-items-catalog .device.is-added {
  background-color: var(--color-black-050);
  color: var(--color-black-500);
}
.line-items-catalog .device:not(.is-added) > .line-item-name,
.line-items-catalog .device:not(.is-added) > .delaer-net {
  cursor: pointer;
}
