.multi-select-minimal-control {
  position: relative;
}

.multi-select-minimal-option-list {
  position: absolute;
  display: none;
  left: 0;
  width: fit-content;
  height: auto;
  max-height: 45vh;
  background-color: #ffffff;
  box-shadow: 0 0.4rem 1.6rem rgb(33 37 41 / 24%);
  z-index: 5;
  overflow: auto;
  border-bottom-right-radius: var(--size-half-pt);
  border-bottom-left-radius: var(--size-half-pt);
}

.multi-select-minimal-option-list .clear-selected-options {
  margin: var(--size-half-pt) var(--size-one-pt) 0;
  padding: 0;
}

.multi-select-minimal-option-list .clear-selected-options:hover,
.multi-select-minimal-option-list .clear-selected-options:focus {
  text-decoration: underline;
}

.multi-select-minimal-option-list .select-option {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
}

.multi-select-minimal-option-list .select-option:hover,
.multi-select-minimal-option-list .select-option:focus {
  box-shadow: inset 2px 0 0 var(--color-primary-500);
}

.multi-select-minimal-control .checkbox-input-abstract {
  margin-bottom: 0;
  margin-right: 1rem;
}

.multi-select-minimal-control .checkbox-input-component > label {
  vertical-align: middle;
}

.additional-selected-options {
  background-color: white;
  color: var(--color-primary-500);
  border: none;
  border-radius: var(--size-one-pt);
  font-size: var(--font-size-body-3);
  line-height: var(--font-size-body-2);
  padding: 0 var(--size-half-pt);
}
