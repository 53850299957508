.landing-section {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 50%;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-image: url('../../../assets/images/bg-image.jpg');
  background-size: cover;
  background-attachment: fixed;
}

.landing-panel-span {
  font-weight: 600;
  color: var(--color-whisper);
  text-transform: uppercase;
}
.landing-panel-span.ie-browser-warning {
  text-transform: none;
}

.landing-left-part,
.landing-right-part {
  padding: 0 calc(2 * var(--size-one-pt));
}

.landing-right-part button {
  margin: calc(3 * var(--size-one-pt)) auto;
  display: block;
  width: 100%;
  max-width: calc(50 * var(--size-one-pt));
}

.ie-browser-warning a {
  color: #fff;
}

@media (max-width: 1300px) {
  .landing-right-part button {
    max-width: calc(45 * var(--size-one-pt));
  }
}

@media (max-width: 1000px) {
  .landing-right-part button {
    max-width: calc(40 * var(--size-one-pt));
  }
}

@media (max-width: 700px) {
  .landing-right-part button {
    max-width: calc(35 * var(--size-one-pt));
  }
}
