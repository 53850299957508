#admin-section .admin-section-content {
  display: grid;
  grid-auto-columns: 1fr 1fr;
  grid-auto-flow: column;
  grid-column-gap: calc(5 * var(--size-one-pt));
}

#admin-section .admin-section-csv-buttons {
  margin-top: calc(1.5 * var(--size-one-pt));
}

#admin-section .admin-section-csv-buttons .badge-btn-component:nth-of-type(n + 2) {
  margin-left: calc(6 * var(--size-one-pt));
}
