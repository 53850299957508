@media (min-width: 1440px) {
  .s2s-modal-component.signature-history-modal {
    width: 80vw;
  }
}

.signature-history-table > tbody > tr > td.actions {
  text-align: right;
  border-top-style: none;
}

.signature-history-table > tbody > tr > td.signers > .signer {
  padding-top: 0.5em;
  margin-top: 0.5em;
  border-top: 1px dashed rgba(0, 0, 0, 0.1);
  display: block;
}
.signature-history-table > tbody > tr > td.signers > .signer:first-child {
  padding-top: 0;
  margin-top: 0;
  border-top-style: none;
}

.signature-history-table > tbody > tr > td.contents > span,
.signature-history-table > tbody > tr > td.signers > .signer > span {
  white-space: nowrap;
  display: block;
}

.signature-history-table > tbody > tr > td.counter-signer {
  max-width: 0;
  width: auto;
}
.signature-history-table > tbody > tr > td.counter-signer > .signer > span,
.signature-history-table > tbody > tr > td.customer-signer > .signer > span {
  text-overflow: ellipsis;
  overflow: hidden;
}

.signature-history-table > tbody > tr.history-data-row > td.type {
  width: 1px;
}
.signature-history-table > tbody > tr.history-data-row > td.contents {
  width: 1px;
}
.signature-history-table > tbody > tr.history-data-row > td.customer-signer {
  width: auto;
}
.signature-history-table > tbody > tr.history-data-row > td.counter-signer {
  width: auto;
}
.signature-history-table > tbody > tr.history-data-row > td.sent,
.signature-history-table > tbody > tr.history-data-row > td.updated {
  width: 12rem;
  text-align: center;
}
.signature-history-table > tbody > tr.history-data-row > td.status {
  width: 1px;
  text-align: right;
}

.signature-history-table > tbody > tr.history-data-row > td.nowrap {
  white-space: nowrap;
}
.signature-history-table > tbody > tr.history-data-row > td.auto-width {
  width: auto;
}

.btn-signature-void {
  font-size: var(--font-size-body-3);
  margin-left: 1em;
}

.void-input-btn {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
}

.void-input-btn .input-component-wrapper {
  max-width: 12rem;
}

.void-input-btn .input-component-wrapper input {
  padding-right: 1.2rem;
  font-size: var(--font-size-body-2);
  height: calc(3.5 * var(--size-one-pt)) !important;
  line-height: calc(3.5 * var(--size-one-pt)) !important;
}

.void-input-btn .input-abstract-component {
  margin-bottom: 0;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
}

.void-input-btn .input-abstract-label {
  font-size: var(--font-size-body-2);
}

.status-voided {
  white-space: nowrap;
}

.btn-signature-new-additional-credit {
  position: absolute;
  right: var(--indent-high);
  bottom: var(--indent-medium);
}
