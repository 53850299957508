#rq-footer {
  position: fixed;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  height: calc(3 * var(--size-one-pt));
  padding: 0;
  text-transform: uppercase;
  text-align: right;
  z-index: 1199;
  display: grid;
  grid-auto-flow: column;
}

#rq-footer > .versions-container {
  font-weight: 400;
  color: var(--color-whisper);
  font-size: var(--font-size-body-2);
  padding: 0 var(--size-one-pt);
  opacity: 0.5;
  background-color: var(--color-silver-chalice);
  display: grid;
  grid-auto-flow: column;
  grid-gap: var(--size-one-pt);
  user-select: none;
  cursor: pointer;
  width: max-content;
  justify-self: end;
}

#rq-footer > .versions-container * {
  cursor: inherit;
}

#rq-footer > .versions-container > section > label:after {
  content: '';
  margin-right: var(--size-half-pt);
}

#rq-footer > .versions-container > section > strong {
  font-weight: 600;
}
