@import './contactsSection.css';

.full-width {
  width: 100%;
}

@media all and (min-width: 1025px) {
  .quote-content-style > .full-width {
    width: 100% !important;
  }
}

.quote-content-style.transparent-bg .card-content {
  background-color: transparent;
}

.quote-content-style.first-section {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-gap: var(--indent-standard);
}

.quote-content-style.first-section > div {
  display: flex;
  flex-direction: column;
}

.quote-content-style #partner-information-section,
.quote-content-style #daas-settings-section,
.quote-content-style #sangoma-cx-settings {
  margin-top: calc(3 * var(--size-one-pt));
}

@media all and (max-width: 570px) {
  .quote-content-style.first-section {
    margin-top: 11.5rem;
  }
}

@media all and (min-width: 1024px) {
  .quote-content-style.first-section {
    grid-template-columns: 1fr 1fr;
  }
}

.quote-card-container {
  display: grid;
  grid-gap: var(--indent-standard);
}

.first-section .quote-card-container {
  display: initial;
}

.first-section .quote-card-container .max-height-section {
  height: 100%;
}

.first-section .quote-card-container .card-block {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.location-title {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.location-title > span {
  font-family: Poppins, 'Open Sans', sans-serif;
  font-weight: 500;
}

.roll-up-btn {
  position: relative;
}

.clone-btn,
.re-push-btn,
.create-revision-btn,
.sync-to-tacking-btn {
  position: relative;
  margin-left: var(--size-one-pt);
}

.clone-btn:first-child,
.create-revision-btn:first-child {
  margin-left: 0;
}

.create-revision-btn + .badge-btn-errors-list .info-tooltip {
  display: flex;
  flex-direction: column;
}

.location-header {
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: calc(2 * var(--size-one-pt));
  align-items: center;
}

.location-quote-status-link {
  margin-left: 1.8rem;
}

.location-quote-status-btn {
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  text-decoration: none;
  transition: all 0.5s;
}

.roll-up-note {
  font-size: var(var(--font-size-body-1));
}

.quote-number-component {
  display: flex;
}

.customer-name {
  flex: 1;
  max-width: 23rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copy-successfully-created-body {
  display: flex;
  flex-direction: column;
}

.location-content-wrapper {
  width: 60%;
}
.location-content-wrapper.switchvox-enabled .solution-type {
  grid-auto-columns: 3fr;
}

.clone-and-allocation-controls {
  display: flex;
  gap: var(--size-one-pt);
  align-items: center;
  padding: 0 calc(2 * var(--size-one-pt));
}

@media all and (min-width: 720px) and (max-width: 1023px), all and (min-width: 1440px) {
  .star-cloud-locations {
    overflow: auto;
    grid-auto-columns: 7rem;
  }
}

.single-location-modal,
.reset-se-modal {
  width: 50rem;
}

.single-location-modal .default-confirm-modal-actions,
.delete-line-item-modal .default-confirm-modal-actions,
.reset-se-modal .default-confirm-modal-actions {
  width: 30rem;
  margin-left: auto;
}

.confirm-email-modal,
.delete-line-item-modal {
  width: auto;
  min-width: 40rem;
  max-width: 50rem;
}
