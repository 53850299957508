.duplicate-location-modal {
  padding: 0;
  overflow: visible;
}

.duplicate-location-modal .default-modal-label p {
  padding: 1.5rem;
  margin: 0;
}

.duplicate-location-modal .default-confirm-modal-actions {
  padding: 1.5rem 2rem 2rem;
}

.duplicate-location-modal .default-modal-label {
  margin: 0;
}

.quantity-of-locations {
  padding: 2rem;
}
