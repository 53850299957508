.amount-checkbox-input {
  display: flex;
}

.checkbox-input {
  width: 1.6rem;
  height: 1.6rem;
  margin: 1.2rem 0.8rem;
  cursor: pointer;
  z-index: 1;
}

.checkbox-input-with-label {
  margin: 0.8rem;
  position: absolute;
}
