.calendar-toolbar-component {
  display: flex;
  padding-bottom: var(--indent-standard);
  align-items: center;
}

.calendar-toolbar-actions {
  display: flex;
}

.prev-month-btn,
.next-month-btn {
  display: flex;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0.4rem;
  align-items: center;
  justify-content: center;
  border: 0.2rem solid transparent;
  border-radius: var(--radius-light);
}

.prev-month-btn {
  margin-right: var(--indent-standard);
  padding-left: 0;
}

.today-btn {
  margin-left: auto;
  border: 0.2rem solid transparent;
  border-radius: var(--radius-light);
}

.today-btn:not([disabled]):hover,
.today-btn:active,
.prev-month-btn:not([disabled]):hover,
.next-month-btn:not([disabled]):hover,
.prev-month-btn:active,
.next-month-btn:active {
  background-image: none;
}

.today-btn:not(:disabled):focus,
.prev-month-btn:not(:disabled):focus,
.next-month-btn:not(:disabled):focus {
  border-color: var(--color-curious-blue);
  box-shadow: 0 0 0 0.4rem rgba(34, 138, 230, 0.6);
}

.current-month {
  margin-left: auto;
  font-weight: 600;
}
