.drawer {
  width: 30rem;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: white;
  margin-top: calc(6 * var(--size-one-pt));
  position: fixed;
  z-index: 1201;
}

.drawer-menu-item {
  display: block;
  cursor: pointer;
  text-decoration: none;
  margin: 0;
  padding: calc(2 * var(--size-one-pt)) 0 calc(2 * var(--size-one-pt)) calc(4 * var(--size-one-pt));
  position: relative;
  white-space: nowrap;
  color: var(--color-shark);
}

.drawer-menu-item:hover {
  background-color: var(--color-section-header-bg);
}

.menu-item-drop-down {
  padding-left: 5rem;
  margin: 0;
  color: var(--color-quill-gray);
  font-size: var(var(--font-size-body-1));
}

.menu-item-drop-down li {
  line-height: 3rem;
  cursor: pointer;
  width: auto;
}

.menu-item-drop-down li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.drawer-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.32);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0px);
  z-index: 1200;
}
