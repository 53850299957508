@import './theme/index.css';

/* Base button styles */

button,
.btn {
  padding: 0 calc(2 * var(--size-one-pt));
  font-size: var(--font-size-button-m);
  font-weight: 600;
  color: var(--button-base-color);
  background-color: var(--color-primary-500);
  border-width: var(--size-thin-line);
  border-style: solid;
  border-color: var(--button-base-border-color);
  border-radius: var(--size-half-pt);
  cursor: pointer;
  outline: none !important;
  white-space: nowrap;
  line-height: calc(4 * var(--size-one-pt) - (2 * var(--size-thin-line)));
  height: calc(4 * var(--size-one-pt));
  margin: 0;
  display: inline-grid;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: var(--size-one-pt);
}

button:hover,
.btn:hover {
  background-color: var(--color-primary-700);
  border-color: transparent;
  color: white;
}

button[disabled],
.btn.disabled {
  cursor: not-allowed !important;
  background-color: var(--color-black-100) !important;
  border-color: transparent !important;
  color: var(--color-black-600) !important;
  text-decoration: none !important;
}

button.dark-bg[disabled],
.btn.disabled.dark-bg {
  cursor: not-allowed !important;
  background-color: var(--color-black-700) !important;
  border-color: transparent !important;
  color: var(--color-black-400) !important;
  text-decoration: none !important;
}

/* Primary */

button.primary,
.btn.primary {
  background-color: var(--color-success-600);
  border-color: transparent;
  color: white;
}

button.primary:hover,
.btn.primary:hover {
  background-color: var(--color-success-700);
  border-color: transparent;
  color: white;
}

/* Secondary */

button.secondary,
.btn.secondary {
  background-color: var(--button-secondary-background-color);
  border-color: var(--button-secondary-border-color);
  color: var(--button-secondary-color);
}

button.secondary:hover,
.btn.secondary:hover {
  background-color: var(--button-secondary-hover-background-color);
  border-color: var(--button-secondary-hover-border-color);
  color: var(--button-secondary-hover-color);
}

/* Tertiary */

button.tertiary,
.btn.tertiary {
  background-color: white;
  border-color: var(--color-primary-100);
  color: var(--color-primary-500);
}

button.tertiary:hover,
.btn.tertiary:hover {
  background-color: var(--color-primary-050);
  border-color: var(--color-primary-100);
  color: var(--color-primary-500);
}

/* Text button */

button.text-btn,
.btn.text-btn {
  background-color: transparent;
  border-color: transparent;
  color: var(--color-primary-500);
}

button.text-btn:hover,
.btn.text-btn:hover {
  background-color: var(--button-text-hover-background-color);
  border-color: var(--button-text-hover-border-color);
  color: var(--button-text-hover-color);
}

/* Critical */

button.critical,
.btn.critical {
  background-color: var(--button-critical-background-color);
  border-color: var(--button-critical-border-color);
  color: var(--button-critical-color);
}

button.critical:hover,
.btn.critical:hover {
  background-color: var(--button-critical-hover-background-color);
  border-color: var(--button-critical-hover-border-color);
  color: var(--button-critical-hover-color);
}

/* Outlined White */

button.outlined-white,
.btn.outlined-white {
  background-color: transparent;
  border-color: white;
  color: white;
}

button.outlined-white:hover,
.btn.outlined-white:hover {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: white;
  color: white;
}

/* Outlined Primary */

button.outlined-primary,
.btn.outlined-primary {
  background-color: transparent;
  border-color: var(--color-primary-100);
  color: var(--color-primary-500);
}

button.outlined-primary:hover,
.btn.outlined-primary:hover {
  background-color: var(--color-primary-050);
  border-color: var(--color-primary-100);
  color: var(--color-primary-500);
}

/* Large buttons */
button.large,
.btn.large,
.btn-group.large > button,
.btn-group.large > .btn,
.btn-group.large > .badge-btn-component > button,
.btn-group.large > .badge-btn-component > .btn,
.btn-collection.large > button,
.btn-collection.large > .btn,
.btn-collection.large > .badge-btn-component > button,
.btn-collection.large > .badge-btn-component > .btn {
  font-size: var(--font-size-button-l);
  line-height: calc(5 * var(--size-one-pt) - 2 * var(--size-thin-line));
  height: calc(5 * var(--size-one-pt));
}
.btn-group.large,
.btn-collection.large {
  height: calc(5 * var(--size-one-pt));
  line-height: calc(5 * var(--size-one-pt) - 2 * var(--size-thin-line));
}

/* Huge buttons */
button.huge,
.btn.huge,
.btn-group.huge > button,
.btn-group.huge > .btn,
.btn-group.huge > .badge-btn-component > button,
.btn-group.huge > .badge-btn-component > .btn,
.btn-collection.huge > button,
.btn-collection.huge > .btn,
.btn-collection.huge > .badge-btn-component > button,
.btn-collection.huge > .badge-btn-component > .btn {
  font-size: var(--font-size-button-xl);
  line-height: calc(7 * var(--size-one-pt) - 2 * var(--size-thin-line));
  height: calc(7 * var(--size-one-pt));
  padding-left: calc(3 * var(--size-one-pt));
  padding-right: calc(3 * var(--size-one-pt));
}
.btn-group.huge,
.btn-collection.huge {
  height: calc(7 * var(--size-one-pt));
  line-height: calc(7 * var(--size-one-pt) - 2 * var(--size-thin-line));
}

/* Small buttons */
button.small,
.btn.small,
.btn-group.small > button,
.btn-group.small > .btn,
.btn-group.small > .badge-btn-component > button,
.btn-group.small > .badge-btn-component > .btn,
.btn-collection.small > button,
.btn-collection.small > .btn,
.btn-collection.small > .badge-btn-component > button,
.btn-collection.small > .badge-btn-component > .btn {
  font-size: var(--font-size-button-s);
  line-height: calc(3 * var(--size-one-pt) - 2 * var(--size-thin-line));
  height: calc(3 * var(--size-one-pt));
  padding-left: var(--size-one-pt);
  padding-right: var(--size-one-pt);
}
.btn-group.small,
.btn-collection.small {
  height: calc(3 * var(--size-one-pt));
  line-height: calc(3 * var(--size-one-pt) - 2 * var(--size-thin-line));
}

button.no-border,
button.no-border:hover,
/*button.no-border:focus,*/
/*button.no-border:active,*/
button.no-border[disabled],
.btn.no-border,
.btn.no-border:hover,
.btn.no-border:focus,
.btn.no-border:active,
.btn.no-border[disabled] {
  border-color: transparent !important;
}

button.no-bg,
button.no-bg:hover,
/*button.no-bg:focus,*/
/*button.no-bg:active,*/
button.no-bg[disabled],
.btn.no-bg,
.btn.no-bg:hover,
/*.btn.no-bg:focus,*/
/*.btn.no-bg:active,*/
.btn.no-bg[disabled] {
  background-color: transparent !important;
}

button.square,
.btn.square {
  width: calc(4 * var(--size-one-pt));
  padding: 0;
  justify-content: center;
}

button.large.square,
.btn.large.square,
.btn-group.large > button.square,
.btn-group.large > .btn.square {
  width: calc(5 * var(--size-one-pt));
}

button.huge.square,
.btn.huge.square,
.btn-group.huge > button.square,
.btn-group.huge > .btn.square {
  width: calc(7 * var(--size-one-pt));
}

button.small.square,
.btn.small.square,
.btn-group.small > button.square,
.btn-group.small > .btn.square {
  width: calc(3 * var(--size-one-pt));
}

button:not(.square) > [class^='icon-']:first-child,
button:not(.square) > [class*=' icon-']:first-child,
.btn:not(.square) > [class^='icon-']:first-child,
.btn:not(.square) > [class*=' icon-']:first-child {
  margin-left: calc(-1 * var(--size-one-pt));
}

button.small:not(.square) > [class^='icon-']:first-child,
button.small:not(.square) > [class*=' icon-']:first-child,
.btn.small:not(.square) > [class^='icon-']:first-child,
.btn.small:not(.square) > [class*=' icon-']:first-child,
.btn-group.small > button:not(.square) > [class^='icon-']:first-child,
.btn-group.small > button:not(.square) > [class*=' icon-']:first-child,
.btn-group.small > .btn:not(.square) > [class^='icon-']:first-child,
.btn-group.small > .btn:not(.square) > [class*=' icon-']:first-child {
  margin-left: calc(-1 * var(--size-half-pt));
}

button.huge:not(.square) > [class^='icon-']:first-child,
button.huge:not(.square) > [class*=' icon-']:first-child,
.btn.huge:not(.square) > [class^='icon-']:first-child,
.btn.huge:not(.square) > [class*=' icon-']:first-child,
.btn-group.huge > button:not(.square) > [class^='icon-']:first-child,
.btn-group.huge > button:not(.square) > [class*=' icon-']:first-child,
.btn-group.huge > .btn:not(.square) > [class^='icon-']:first-child,
.btn-group.huge > .btn:not(.square) > [class*=' icon-']:first-child {
  margin-left: calc(-1.5 * var(--size-one-pt));
}

.btn-group {
  display: inline-grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  height: calc(4 * var(--size-one-pt));
  line-height: calc(4 * var(--size-one-pt) - 2 * var(--size-thin-line));
}

.btn-group > button,
.btn-group > .btn,
.btn-group > .badge-btn-component {
  line-height: 1;
}

.btn-group > button,
.btn-group > .btn,
.btn-group > .badge-btn-component > button,
.btn-group > .badge-btn-component > .btn {
  border-radius: 0;
  border-left-style: none;
}

.btn-group > button:first-child,
.btn-group > .btn:first-child,
.btn-group > .badge-btn-component:first-child > button,
.btn-group > .badge-btn-component:first-child > .btn {
  border-left-style: solid;
  border-top-left-radius: var(--size-half-pt);
  border-bottom-left-radius: var(--size-half-pt);
}

.btn-group > button:last-child,
.btn-group > .btn:last-child,
.btn-group > .badge-btn-component:last-child > button,
.btn-group > .badge-btn-component:last-child > .btn {
  border-top-right-radius: var(--size-half-pt);
  border-bottom-right-radius: var(--size-half-pt);
}

.btn-collection {
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: var(--size-one-pt);
  align-items: center;
}
.btn-collection > * {
  margin: 0 !important;
}

button.blue-border {
  border-color: var(--color-primary-500);
}

/* Focus-Visible Styles */

button:focus-visible,
.btn:focus-visible {
  background-color: var(--button-base-focus-background-color) !important;
  border-color: var(--button-base-focus-border-color);
  color: var(--button-base-focus-color);
}

button.primary:focus-visible,
.btn.primary:focus-visible {
  background-color: var(--button-primary-focus-background-color) !important;
  border-color: var(--button-primary-focus-border-color);
  color: var(--button-primary-focus-color);
}

button.secondary:focus-visible,
.btn.secondary:focus-visible {
  background-color: var(--button-secondary-focus-background-color) !important;
  border-color: var(--button-secondary-focus-border-color) !important;
  color: var(--button-secondary-focus-color) !important;
}

button.tertiary:focus-visible,
.btn.tertiary:focus-visible {
  background-color: var(--button-tertiary-focus-background-color) !important;
  border-color: var(--button-tertiary-focus-border-color) !important;
  color: var(--button-tertiary-focus-color) !important;
}

button.critical:focus-visible,
.btn.critical:focus-visible {
  background-color: var(--button-critical-focus-background-color) !important;
  border-color: var(--button-critical-focus-border-color) !important;
  color: var(--button-critical-focus-color) !important;
}

button.text-btn:focus-visible,
.btn.text-btn:focus-visible {
  background-color: var(--button-text-focus-background-color) !important;
  border-color: var(--button-text-focus-border-color) !important;
  color: var(--button-text-focus-color) !important;
}

button.outlined-white:focus-visible,
.btn.outlined-white:focus-visible {
  background-color: rgba(255, 255, 255, 0.12) !important;
  border-color: white !important;
  color: white !important;
}

button.outlined-primary:focus-visible,
.btn.outlined-primary:focus-visible {
  background-color: var(--color-primary-050) !important;
  border-color: var(--color-primary-100) !important;
  color: var(--color-primary-500) !important;
}
