.bulk-markup-row {
  display: flex;
  flex-direction: row;
  width: 75%;
}

.bulk-markup-row:first-of-type {
  margin-top: var(--indent-standard);
}

.bulk-markup-row .bulk-markup-input {
  width: 50%;
  grid-template-columns: 1fr 1fr;
}

.bulk-markup-row .bulk-markup-label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 30px;
}
