:root {
  --color-primary-900: #0b182e;
  --color-primary-800: #112547;
  --color-primary-700: #183260;
  --color-primary-600: #1e3f78;
  --color-primary-500: #244c91;
  --color-primary-400: #335899;
  --color-primary-300: #4a6ead;
  --color-primary-200: #7593c4;
  --color-primary-100: #a3b6d7;
  --color-primary-050: #d1daeb;
  --color-primary-025: #e8edf5;

  --color-secondary-900: #3e1438;
  --color-secondary-800: #4e1946;
  --color-secondary-700: #5d1e54;
  --color-secondary-600: #7c2870;
  --color-secondary-500: #9c328c;
  --color-secondary-400: #a54697;
  --color-secondary-300: #af5ba3;
  --color-secondary-200: #b96fae;
  --color-secondary-100: #cd98c5;
  --color-secondary-050: #ebd6e8;

  --color-tertiary-900: #073045;
  --color-tertiary-800: #0b4768;
  --color-tertiary-700: #0e5f8a;
  --color-tertiary-600: #1277ac;
  --color-tertiary-500: #158fcf;
  --color-tertiary-400: #24a5e9;
  --color-tertiary-300: #48b4ec;
  --color-tertiary-200: #6dc3f0;
  --color-tertiary-100: #91d2f4;
  --color-tertiary-050: #b6e1f8;
  --color-tertiary-025: #c3eaff;

  --color-success-900: #03350c;
  --color-success-800: #055012;
  --color-success-700: #066a18;
  --color-success-600: #07841e;
  --color-success-500: #099f24;
  --color-success-400: #2cad43;
  --color-success-300: #4fba63;
  --color-success-200: #72c882;
  --color-success-100: #96d6a1;
  --color-success-050: #dcf1e0;
  --color-success-025: #f4faf5;

  --color-warning-900: #543f00;
  --color-warning-800: #7e5f00;
  --color-warning-700: #a77e00;
  --color-warning-600: #d19d00;
  --color-warning-500: #fbbd00;
  --color-warning-400: #fcc624;
  --color-warning-300: #fcd049;
  --color-warning-200: #fdd96d;
  --color-warning-100: #fde392;
  --color-warning-050: #fef6db;
  --color-warning-025: #fdfaef;

  --color-danger-900: #420307;
  --color-danger-800: #64040b;
  --color-danger-700: #85050f;
  --color-danger-600: #a60712;
  --color-danger-500: #c70816;
  --color-danger-400: #cf2b37;
  --color-danger-300: #d74f59;
  --color-danger-200: #df727a;
  --color-danger-100: #e7959b;
  --color-danger-050: #f7dcde;
  --color-danger-025: #fdf4f4;

  --color-black-900: #001221;
  --color-black-800: #192936;
  --color-black-700: #33414d;
  --color-black-600: #4c5863;
  --color-black-500: #7f888f;
  --color-black-400: #99a0a6;
  --color-black-300: #b2b7bc;
  --color-black-200: #cccfd2;
  --color-black-100: #e5e6e8;
  --color-black-050: #f2f2f3;

  --color-states-hover: rgba(36, 57, 145, 0.04);
  --color-states-focused: rgba(36, 57, 145, 0.12);
  --color-states-pressed: rgba(36, 57, 145, 0.12);
  --color-states-dragged: rgba(36, 57, 145, 0.08);
  --color-states-selected: rgba(36, 57, 145, 0.08);

  --color-blue-gradient-100: #1c275d;
  --color-blue-gradient-50: #364cb7;
  --color-blue-gradient-0: #56a1ce;

  --color-orange-gradient-100: #ff821b;
  --color-orange-gradient-0: #fcd049;
}
