.package-details-title {
  display: grid;
  grid-template-rows: auto auto;
  align-items: center;
  font-size: initial;
}

.package-details-subtitle {
  font-weight: 400;
  font-size: var(--font-size-body-2);
  line-height: calc(3 * var(--size-one-pt));
  color: var(--color-slate-grey);
}

.package-details-dialog-actions {
  justify-content: space-between;
}

.package-details-dialog-actions .right-aligned-actions {
  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  grid-gap: var(--indent-high);
  align-items: center;
}

.package-details-dialog-btn-cancel {
  margin-left: auto;
}

.package-details-warning {
  display: block;
  margin: 0.9rem 0;
  color: var(--color-alabaster);
  line-height: 1.6rem;
  padding: 0.7rem 1rem 0.7rem 3.5rem;
  opacity: 0.9;
  border-radius: 0.7rem;
  background-color: #e03131;
}

.package-details-dialog-content .table-row {
  grid-template-columns: 3fr 1fr 1fr;
}

.package-details-dialog-content .error-sub-title {
  color: var(--color-danger-400);
  padding-left: var(--indent-high);
  font-size: var(--font-size-body-2);
}

.package-details-dialog-content .icon-download,
.package-details-dialog-content .icon-warning {
  font-size: 1.4em;
  vertical-align: middle;
}

.package-details-dialog-content .icon-warning {
  font-size: 1.6em;
}

.package-details-dialog-content .iconButton-with-tooltip-wrapper {
  display: inline-block;
}

.package-details-dialog-content .iconButton-with-tooltip-wrapper button {
  font-size: var(--font-size-button-m);
  font-weight: 600;
  background-color: initial;
  line-height: initial;
  height: initial;
  display: initial;
}

.package-details-dialog-content .iconButton-with-tooltip-wrapper .download-taxes-button {
  padding: 0 0 0 calc(2 * var(--size-one-pt));
  color: var(--color-black-900);
}

.package-details-dialog-content .iconButton-with-tooltip-wrapper .tax-error-icon {
  padding: 0;
  color: var(--color-danger-400);
  font-size: var(--font-size-button-s);
}
