.quotes-table-wrapper {
  font-size: var(--font-size-body-2);
}

.quotes-list > tbody > tr {
  cursor: pointer;
}

.quotes-list .quotes-table-cell_order_type {
  min-width: calc(17 * var(--size-one-pt));
}

.spanish .quotes-list .quotes-table-cell_order_type {
  min-width: calc(25 * var(--size-one-pt)) !important;
}
