.s2s-modal-portal {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
}

.s2s-modal-overlay {
  display: grid;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.32);
}

.s2s-modal-component {
  width: 100vw;
  border: 1px solid var(--color-silver-sand);
  border-radius: var(--radius-light);
  background-color: var(--color-alabaster);
  box-shadow: 0 0.3rem 0.5rem hsla(0, 0%, 0%, 0.2);
}

.s2s-modal-component:focus {
  outline: none !important;
}

@media all and (min-width: 780px) {
  .s2s-modal-component {
    width: 90vw;
  }
}

@media all and (min-width: 1200px) {
  .s2s-modal-component {
    width: 80vw;
  }
}

@media all and (min-width: 1440px) {
  .s2s-modal-component {
    width: 102.4rem;
  }
}

.s2s-modal-title {
  position: relative;
  display: grid;
  padding: calc(var(--size-half-pt) * 5) calc(var(--size-half-pt) * 6);
  grid-auto-columns: auto;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  align-content: center;
  background-color: white;
  border-bottom: var(--size-thin-line) solid var(--color-black-200);
}

.s2s-modal-title-close-btn {
  padding-right: 0;
  align-self: start;
  right: var(--indent-high);
  color: var(--color-nevada);
  background-color: transparent;
}

button.s2s-modal-title-close-btn:hover,
button.s2s-modal-title-close-btn:focus {
  color: var(--color-dodger-blue);
  background-image: none;
}

.s2s-modal-title header,
.s2s-modal-title header span,
.s2s-modal-title header div {
  font-family: Poppins, 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 2rem;
}
.s2s-modal-title header small {
  font-family: Poppins, 'Open Sans', sans-serif;
  font-weight: 400;
}

.s2s-modal-actions {
  position: relative;
  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  grid-gap: var(--indent-high);
  align-items: center;
  justify-content: end;
  padding: calc(var(--size-half-pt) * 4) calc(var(--size-half-pt) * 6);
  background-color: white;
  border-top: var(--size-thin-line) solid var(--color-black-200);
}

.s2s-modal-content {
  padding: calc(2 * var(--size-one-pt));
  min-height: 35rem;
  max-height: 60vh;
  background-color: var(--color-white);
  overflow: auto;
  position: relative;
}
.s2s-modal-content:focus {
  outline: none;
}

.s2s-modal-content > .rq-table.sticky > thead > tr > th,
.s2s-modal-content > .rq-table > thead.sticky > tr > th {
  top: calc(-2 * var(--size-one-pt) - 1px);
}

.s2s-modal-content .loading-wrapper {
  margin: auto;
}

@media all and (max-height: 600px) {
  .s2s-modal-content {
    min-height: initial;
    height: 100vh;
  }
}
