@import './theme/index.css';

a {
  color: var(--color-primary-500);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
