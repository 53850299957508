@import './theme/index.css';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  line-height: 1.25em;
  letter-spacing: 0.15px;
  margin: 0;
}

h1 {
  font-style: normal;
  font-size: calc(6.5 * var(--size-one-pt));
  font-weight: 600;
  line-height: 1.25em;
  letter-spacing: 0.15px;
}

h2 {
  font-size: calc(4.5 * var(--size-one-pt));
  font-weight: 400;
}

h3 {
  font-size: calc(2.5 * var(--size-one-pt));
  font-weight: 700;
}

h4 {
  font-size: calc(2.5 * var(--size-one-pt));
  font-weight: 600;
}

h5 {
  font-size: calc(2 * var(--size-one-pt));
  font-weight: 700;
}

h6 {
  font-size: calc(1.5 * var(--size-one-pt));
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

p {
  margin: 0;
  padding: 0;
}

.light-bold {
  font-weight: 600;
}
