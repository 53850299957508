.quote-status-component {
  display: inline-grid;
  grid-gap: var(--size-one-pt);
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  align-items: center;
}

.quote-status-circle {
  width: calc(3 * var(--size-one-pt));
  height: calc(3 * var(--size-one-pt));
  background: var(--color-whisper);
  border-radius: 100%;
}

.quote-status-btn {
  display: inline-block;
  padding: var(--size-half-pt) var(--size-one-pt);
  color: inherit;
  text-decoration: none;
  transition: all 0.5s;
}
.header-quote-info .quote-status-link > .quote-status-btn {
  margin-right: calc(-1 * var(--size-one-pt));
}
