.filter-input-wrapper {
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 25%;
}

.filter-input-wrapper .icon-search {
  display: inline-block;
  font-size: 3.2rem;
  left: 0.6rem;
  position: absolute;
  top: 0.5rem;
  color: var(--color-black-500);
}

.filter-input-wrapper:focus-within .icon-search {
  color: var(--color-primary-500);
}

.filter-input {
  flex: 1 0 100%;
}

.filter-input input {
  background: var(--color-white);
  padding: 0 10rem 0 5rem !important;
}
