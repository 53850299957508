.badge-btn-errors-list {
  --tooltip-bg-color-primary: var(--color-black-900);
  --tooltip-bg-color-info: var(--color-black-900);
  --tooltip-bg-opacity: 0.95;

  position: absolute;
  display: none;
  flex-flow: row wrap;
  padding: 0;
  top: 100%;
  color: var(--color-alabaster);
  background-color: transparent;
  z-index: 999;
  font-size: var(--font-size-body-2);
  max-width: 80vw;
}

.badge-btn-errors-list > ul,
.badge-btn-errors-list > .badge-btn-tooltip {
  display: block;
  position: relative;
  width: max-content;
  padding: var(--size-one-pt) calc(2 * var(--size-one-pt));
  text-align: left;
  color: var(--color-white);
  white-space: pre-line;
  margin: var(--size-one-pt) 0 0 0;
  opacity: 1;
}

.badge-btn-errors-list ul:before,
.badge-btn-errors-list .badge-btn-tooltip:before {
  content: '';
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  opacity: var(--tooltip-bg-opacity);
  border-radius: var(--size-one-pt);
  background-color: var(--tooltip-bg-color-primary);
}

.badge-btn-errors-list .badge-btn-tooltip::after,
.badge-btn-errors-list ul::after {
  content: '';
  position: absolute;
  top: calc(-2 * var(--size-one-pt));
  border-width: var(--size-one-pt);
  border-style: solid;
  border-color: transparent transparent var(--tooltip-bg-color-primary) transparent;
  opacity: var(--tooltip-bg-opacity);
}

.badge-btn-errors-list > ul > *,
.badge-btn-errors-list > .badge-btn-tooltip > * {
  position: relative;
}

.calc-revers {
  display: block;
  opacity: 0;
}

.badge-btn-errors-list ul:before {
  background-color: var(--tooltip-bg-color-primary);
}

.badge-btn-errors-list ul li {
  text-align: left;
  overflow: hidden;
  max-width: 80vw;
  width: max-content;
}

.badge-btn-errors-list.blue-error-warning ul li {
  max-width: 80vw;
  text-align: left;
  padding-top: var(--indent-low);
}

.badge-btn-errors-list.blue-error-warning ul:before {
  background-color: var(--tooltip-bg-color-primary);
}

.badge-btn-errors-list.top-align {
  top: auto;
  bottom: 100%;
  flex-flow: wrap-reverse;
}

.badge-btn-errors-list.list-array.top-align {
  bottom: calc(100% + var(--size-one-pt));
}

.badge-btn-errors-list.top-align .badge-btn-tooltip {
  margin-bottom: var(--size-one-pt);
  margin-top: 0;
}

.badge-btn-errors-list.top-align .badge-btn-tooltip::after,
.badge-btn-errors-list.top-align ul::after {
  bottom: calc(-2 * var(--size-one-pt));
  top: auto;
  transform: rotate(180deg);
}

.badge-btn-errors-list.list-array:not(.blue-error-warning) {
  right: unset;
}

.badge-btn-errors-list:not(.blue-error-warning) ul::after {
  border-color: transparent transparent var(--tooltip-bg-color-primary) transparent;
}

.badge-btn-errors-list .badge-btn-tooltip.info-tooltip:before {
  background-color: var(--tooltip-bg-color-info);
}

.badge-btn-errors-list.top-align .badge-btn-tooltip.info-tooltip {
  top: inherit;
}

.badge-btn-errors-list .badge-btn-tooltip.info-tooltip::after {
  border-color: transparent transparent var(--tooltip-bg-color-info) transparent;
}

.badge-btn-component > .badge-btn-errors-list.right-align-tooltip {
  left: unset;
  right: 0;
}
.badge-btn-component > .badge-btn-errors-list.right-align-tooltip .badge-btn-tooltip {
  justify-self: end;
}
.badge-btn-component > .badge-btn-errors-list.right-align-tooltip .badge-btn-tooltip:after,
.badge-btn-component > .badge-btn-errors-list.right-align-tooltip ul::after {
  left: unset;
  right: var(--size-one-pt);
}

.badge-btn-component > .badge-btn-errors-list.left-align-tooltip {
  left: 0;
  right: unset;
}

.badge-btn-component > .badge-btn-errors-list.left-align-tooltip .badge-btn-tooltip {
  justify-self: start;
}
.badge-btn-component > .badge-btn-errors-list.left-align-tooltip .badge-btn-tooltip:after,
.badge-btn-component > .badge-btn-errors-list.left-align-tooltip ul::after {
  left: var(--size-one-pt);
  right: unset;
}

@media (max-width: 1200px) {
  .badge-btn-component > .badge-btn-errors-list.right-align-tooltip {
    left: 0;
    right: unset;
  }
  .badge-btn-component > .badge-btn-errors-list.right-align-tooltip .badge-btn-tooltip:after,
  .badge-btn-component > .badge-btn-errors-list.right-align-tooltip ul::after {
    left: var(--size-one-pt);
    right: unset;
  }
}

@media (max-width: 1100px) {
  .badge-btn-errors-list .badge-btn-tooltip,
  .badge-btn-errors-list.blue-error-warning ul li {
    width: 60vw;
  }
}

@media (max-width: 700px) {
  .badge-btn-errors-list .badge-btn-tooltip,
  .badge-btn-errors-list.blue-error-warning ul li {
    width: 50vw;
  }
}
