.retrieve-quotes {
  border: var(--size-thin-line) solid var(--color-alto);
  margin-top: calc(6 * var(--size-one-pt));
  padding: 0;
  width: 100%;
  z-index: 1;
}

.sort-wrapper {
  display: inline-flex;
  align-items: center;
  margin-top: 0.5rem;
}

.filter-toolbar {
  position: relative;
  width: 100%;
  white-space: nowrap;
  border-bottom: var(--size-thin-line) solid var(--color-alto);
  display: flex;
  align-items: center;
  height: calc(7 * var(--size-one-pt));
  padding: 0 calc(2 * var(--size-one-pt));
}

.filter-toolbar .multi-select-minimal-control {
  margin-left: 1rem;
}

.quote-table-loader {
  width: 4rem;
  height: 4rem;
  position: relative;
  left: calc(50% - 2.5rem);
  margin: 2rem 0;
}

.no-results {
  text-align: center;
  padding: 2rem 0;
}
