.rq-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border: var(--size-thin-line) solid var(--color-white-smoke);
}

.rq-table.auto-width {
  width: auto;
}

.rq-table.hover > tbody > tr:hover {
  background-color: var(--color-alto) !important;
}

.rq-table > thead > tr > th,
.rq-table > tbody > tr > th {
  font-family: Poppins, 'Open Sans', sans-serif;
  font-weight: 600;
  color: var(--color-black-900);
  background-color: var(--color-black-050);
  text-align: left;
  white-space: nowrap;
  padding: 0.5em 0.75em;
}

.rq-table.striped > thead > tr > th,
.rq-table > tbody > tr > th {
  box-shadow: inset 0 -1px 0 var(--color-black-100);
}

.rq-table > tbody > tr > th {
  box-shadow: none;
}

.rq-table > thead > tr > th.sortable {
  position: relative;
  padding-right: 1.25em;
  cursor: pointer;
}

.rq-table > thead > tr > th.sortable:before,
.rq-table > thead > tr > th.sortable:after {
  font-family: icomoon;
  display: none;
  width: 1em;
  position: absolute;
  right: 0;
  color: var(--color-slate-grey);
  line-height: 0.5em;
}

.rq-table > thead > tr > th.sortable:before {
  top: 25%;
  bottom: 50%;
  content: '\e912';
}

.rq-table > thead > tr > th.sortable:after {
  top: 50%;
  bottom: 25%;
  content: '\e913';
}

.rq-table > thead > tr > th.sortable:hover:before,
.rq-table > thead > tr > th.sortable:hover:after {
  display: block;
}

.rq-table > thead > tr > th.sortable.asc,
.rq-table > thead > tr > th.sortable.asc:before,
.rq-table > thead > tr > th.sortable.desc,
.rq-table > thead > tr > th.sortable.desc:after {
  color: var(--color-primary-500);
}

.rq-table > thead > tr > th.sortable.asc:before {
  display: block;
}

.rq-table > thead > tr > th.sortable.desc:after {
  display: block;
}

.rq-table:not(.striped) > thead + tbody > tr > td {
  border-top: var(--size-thin-line) solid var(--color-black-100);
}

.rq-table > tbody > tr > th:first-child {
  text-align: right;
}

.rq-table > tbody > tr > td {
  padding: 0.5em 0.75em;
  vertical-align: top;
  text-align: left;
}

.rq-table > tbody > tr,
.rq-table > tbody > tr.odd,
.rq-table.striped > tbody > tr:nth-of-type(odd) {
  background-color: white;
}

.rq-table > tbody > tr.even,
.rq-table.striped > tbody > tr:nth-of-type(even) {
  background-color: var(--color-black-050);
}

.rq-table.fit-width > thead > tr > th,
.rq-table.fit-width > thead > tr > td,
.rq-table.fit-width > tbody > tr > th,
.rq-table.fit-width > tbody > tr > td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 0;
}

.rq-table.sticky > thead > tr > th,
.rq-table > thead.sticky > tr > th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.rq-table tr > th.auto-width,
.rq-table tr > th.auto-width-nowrap,
.rq-table > tbody > tr > td.auto-width,
.rq-table > tbody > tr > td.auto-width-nowrap {
  width: 1px;
  max-width: unset;
}

.rq-table tr > th.auto-width-nowrap,
.rq-table > tbody > tr > td.auto-width-nowrap {
  white-space: nowrap;
}

.rq-table.fit-width > tbody > tr > td.auto-width,
.rq-table.fit-width > tbody > tr > td.auto-width-nowrap {
  overflow: unset;
  text-overflow: unset;
}

.rq-table.fit-width > tbody > tr > td.auto-width {
  white-space: unset;
}

.rq-table > thead > tr > th.auto-width-ellipsis,
.rq-table > thead > tr > td.auto-width-ellipsis,
.rq-table > tbody > tr > th.auto-width-ellipsis,
.rq-table > tbody > tr > td.auto-width-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: unset;
  max-width: 0;
}

.rq-table.valign-top > thead > tr > th,
.rq-table.valign-top > tbody > tr > th,
.rq-table.valign-top > tbody > tr > td {
  vertical-align: top;
}

.rq-table.valign-middle > thead > tr > th,
.rq-table.valign-middle > tbody > tr > th,
.rq-table.valign-middle > tbody > tr > td {
  vertical-align: middle;
}

.rq-table.valign-bottom > thead > tr > th,
.rq-table.valign-bottom > tbody > tr > th,
.rq-table.valign-bottom > tbody > tr > td {
  vertical-align: bottom;
}

.rq-table > thead > tr.valign-top > th,
.rq-table > tbody > tr.valign-top > th,
.rq-table > tbody > tr.valign-top > td {
  vertical-align: top;
}

.rq-table > thead > tr.valign-middle > th,
.rq-table > tbody > tr.valign-middle > th,
.rq-table > tbody > tr.valign-middle > td {
  vertical-align: middle;
}

.rq-table > thead > tr.valign-bottom > th,
.rq-table > tbody > tr.valign-bottom > th,
.rq-table > tbody > tr.valign-bottom > td {
  vertical-align: bottom;
}

.rq-table > thead > tr > th.valign-top,
.rq-table > tbody > tr > th.valign-top,
.rq-table > tbody > tr > td.valign-top {
  vertical-align: top;
}

.rq-table > thead > tr > th.valign-middle,
.rq-table > tbody > tr > th.valign-middle,
.rq-table > tbody > tr > td.valign-middle {
  vertical-align: middle;
}

.rq-table > thead > tr > th.valign-bottom,
.rq-table > tbody > tr > th.valign-bottom,
.rq-table > tbody > tr > td.valign-bottom {
  vertical-align: bottom;
}

.rq-table.align-left > thead > tr > th,
.rq-table.align-left > tbody > tr > th,
.rq-table.align-left > tbody > tr > td {
  text-align: left;
}

.rq-table.align-center > thead > tr > th,
.rq-table.align-center > tbody > tr > th,
.rq-table.align-center > tbody > tr > td {
  text-align: center;
}

.rq-table.align-right > thead > tr > th,
.rq-table.align-right > tbody > tr > th,
.rq-table.align-right > tbody > tr > td {
  text-align: right;
}

.rq-table > thead > tr.align-left > th,
.rq-table > tbody > tr.align-left > th,
.rq-table > tbody > tr.align-left > td {
  text-align: left;
}

.rq-table > thead > tr.align-center > th,
.rq-table > tbody > tr.align-center > th,
.rq-table > tbody > tr.align-center > td {
  text-align: center;
}

.rq-table > thead > tr.align-right > th,
.rq-table > tbody > tr.align-right > th,
.rq-table > tbody > tr.align-right > td {
  text-align: right;
}

.rq-table > thead > tr > th.align-left,
.rq-table > tbody > tr > th.align-left,
.rq-table > tbody > tr > td.align-left {
  text-align: left;
}

.rq-table > thead > tr > th.align-center,
.rq-table > tbody > tr > th.align-center,
.rq-table > tbody > tr > td.align-center {
  text-align: center;
}

.rq-table > thead > tr > th.align-right,
.rq-table > tbody > tr > th.align-right,
.rq-table > tbody > tr > td.align-right {
  text-align: right;
}
