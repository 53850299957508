@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('./icons/icons.css');
@import url('./table.css');
@import './styles/typography.css';
@import './styles/buttons.css';
@import './styles/links.css';
@import './styles/sorting-bar.css';

* {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
}

html {
  font-size: var(--font-size-root);
}

input,
textarea,
select,
.textarea-like-string {
  font-size: var(--font-size-body-2);
}

[class^='icon-'] {
  font-size: 1.5em;
}

[hidden] {
  display: none !important;
}

body {
  background-color: var(--color-body-bg);
  font-size: var(--font-size-body-1);
  line-height: calc(3 * var(--size-one-pt));
  color: var(--color-black-900);
  margin: 0;
}

body.body-overflow-hidden {
  overflow: hidden;
}

#root {
  min-width: 37.5rem;
  position: relative;
}

*:-webkit-autofill,
*:-webkit-autofill::first-line,
*:-webkit-autofill:hover,
*:-webkit-autofill:focus,
*:-webkit-autofill:active {
  height: calc(5 * var(--size-one-pt)) !important;
  font-size: var(--font-size-body-1) !important;
}

.max-width-container {
  max-width: 160rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 var(--size-one-pt);
}

.default-confirm-modal {
  padding: calc(2 * var(--size-one-pt)) calc(3 * var(--size-one-pt));
  position: fixed;
  min-width: 40rem;
  max-width: 50rem;
  left: 50%;
  top: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  text-align: left;
  overflow: hidden;
  border: 1px solid rgb(204, 204, 204);
  background: var(--color-alabaster);
  border-radius: var(--radius-light);
  outline: none;
}

.default-modal-label {
  display: inline-block;
  font-weight: normal;
  margin: 0 0 2rem 0;
  width: 100%;
}

.default-modal-label p.light-bold {
  margin-bottom: calc(var(--size-one-pt) * 2);
  font-weight: 600;
  font-size: calc(2.25 * var(--size-one-pt));
  letter-spacing: calc(1em / 100);
  line-height: calc(3 * var(--size-one-pt));
}

.default-overlay-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.32);
  z-index: 9999;
}

.default-confirm-modal-actions {
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  grid-column-gap: 2rem;
  width: auto;
}

.card-section {
  position: relative;
  display: block;
  margin-top: calc(19 * var(--size-one-pt));
}

@media all and (max-width: 1200px) {
  .card-section {
    top: 25rem;
  }
}

/*Change text-align:left => text-align:center when Partner section will back*/
.quote-content-style {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: calc(3 * var(--size-one-pt));
  text-align: left;
}
.quote-content-style:last-child {
  margin-bottom: calc(7 * var(--size-one-pt));
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.calc-animation {
  transition: all 300ms linear 0ms;
}

.calc-animation.flash-update-value {
  background-color: var(--color-chateau-green) !important;
  color: var(--color-whisper) !important;
}

.dialog-header-left header {
  padding: 0;
}

@media (min-width: 768px) {
  .line-item-row > div:hover {
    background-color: #f4f5f7 !important;
  }
}

.field-required {
  text-transform: uppercase;
  color: red !important;
  margin-left: 5px;
}

.navbar-item-disabled {
  color: gray !important;
  padding: 2rem 2rem 2rem 3.6rem !important;
  min-height: 0 !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sm {
  display: none !important;
}

.catalog-table-list > div {
  padding-bottom: 0 !important;
}

@media (min-width: 768px) {
  .sm {
    display: initial !important;
  }
}

.modal-dialog-overlay {
  position: fixed;
  display: grid;
  background-color: rgba(0, 0, 0, 0.32);
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
}

.modal-dialog-overlay > .modal-dialog-content {
  background-color: #f8f9fa;
  width: 50rem;
  margin: auto;
  padding: 1.6rem;
  border-radius: var(--radius-light);
  font-size: var(var(--font-size-body-1));
  font-weight: 400;
  color: var(--color-shark);
}

.modal-dialog-overlay > .modal-dialog-content:focus {
  outline: none;
}

.modal-dialog-overlay > .modal-dialog-content > .controls {
  display: flex;
  margin-top: 2rem;
  padding-top: 1.6rem;
  justify-content: flex-end;
  border-top: 1px solid var(--color-alto);
}

.modal-dialog-overlay > .modal-dialog-content > .modal-controls {
  display: flex;
  margin-top: 2rem;
  padding-top: 1.6rem;
  justify-content: center;
  border-top: 1px solid var(--color-alto);
}

.modal-dialog-overlay > .modal-dialog-content > .modal-controls button {
  flex-basis: 25%;
  margin: 0 1rem;
}

.modal-dialog-overlay > .modal-dialog-content > .controls button {
  flex-basis: 25%;
  color: var(--color-alabaster);
}

.modal-dialog-overlay > .modal-dialog-content > .controls .btn-yes {
  background-color: var(--color-chateau-green);
}

.modal-dialog-overlay > .modal-dialog-content > .controls .btn-cancel {
  background-color: var(--color-red);
}

.icon-error {
  display: block;
  font-size: 2.4rem;
  margin-top: 1px;
  margin-left: -2.9rem;
  float: left;
  color: #fff;
  transform: rotate(180deg);
}

#location-ictp-section .card-content,
#customer-order-ictp-section .card-content {
  padding: 0;
}

.text-hint {
  color: var(--color-black-600);
  cursor: default;
}

.strong {
  font-weight: 600;
}

.text-align-center {
  text-align: center;
}

.inline-grid-columns {
  display: inline-grid;
  align-items: center;
  justify-content: start;
  grid-auto-flow: column;
  grid-gap: var(--size-one-pt);
}
.inline-grid-columns > * {
  margin: 0 !important;
}
