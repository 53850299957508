.percent-slider-component {
  text-align: left;
  padding: 0;
  margin: 0 0 1.2rem 0;
  position: relative;
}

.percent-slider-label-wrapper {
  display: none;
  padding-right: 3rem;
}

.percent-slider-label,
.percent-slider-hint {
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 50%;
  display: block;
  margin-top: 0.3rem;
  width: 100%;
}

.percent-slider-hint {
  color: var(--color-oslo-gray);
}

.percent-slider.rc-slider,
.percent-slider-limit.rc-slider {
  width: 95%;
  margin: 0 auto;
  padding: 0;
}

.percent-slider.rc-slider-disabled,
.percent-slider-limit.rc-slider-disabled {
  background: transparent;
}

.percent-slider-limit.rc-slider {
  margin-top: 0.5rem;
  height: 3rem;
  border-radius: 0;
}

.percent-slider.rc-slider {
  margin-bottom: 0.5rem;
}

.percent-slider-limit .rc-slider-rail {
  background: transparent;
  border-color: transparent;
}

.percent-slider-limit.rc-slider > * {
  border-radius: 0;
}

.percent-slider-limit .rc-slider-track {
  height: 100%;
  background: linear-gradient(to right, rgba(58, 195, 84, 0), rgba(58, 195, 84, 1)) !important;
}

.percent-slider-limit .rc-slider-handle {
  background: transparent;
  border: none;
  border-top: 3.5rem solid #9e9e9e;
  border-left: 1.5rem solid transparent;
  border-right: 1.5rem solid transparent;
  border-radius: 30%;
  transform: translateX(-30%);
}

.percent-slider-limit.rc-slider-disabled .rc-slider-handle,
.percent-slider-limit .rc-slider-handle:hover,
.percent-slider-limit .rc-slider-handle:focus,
.percent-slider-limit .rc-slider-handle:active {
  box-shadow: none;
  border-color: transparent;
  border-top-color: #9e9e9e;
  background: transparent;
}

.percent-slider-limit-label {
  padding: 1rem 1.5rem 0;
}

.percent-slider-limit-error {
  position: relative;
  width: 100%;
  height: 2.5rem;
}

.percent-slider .rc-slider-track {
  background-color: #9e9e9e;
}

.percent-slider .rc-slider-handle {
  background: var(--color-cerulean-blue);
  border-radius: 25% / 50%;
  border: none;
  transform: translateX(-30%);
  padding: 1.3rem 2rem;
  margin-top: -1.3rem;
}

.percent-slider.rc-slider-disabled .rc-slider-handle {
  background-color: var(--color-hit-gray);
}

.percent-slider-legend-wrapper {
  display: flex;
  min-height: 5rem;
  margin: 1rem 1.4rem;
  justify-content: space-between;
}

.percent-slider-legend-left,
.percent-slider-legend-right {
  display: flex;
  flex-direction: column;
}

.percent-slider-legend-right {
  align-items: flex-end;
}

.percent-slider-value {
  font-weight: bold;
}

.mad-amount-labels {
  display: flex;
  height: 2.5rem;
  margin-top: 2rem;
  justify-content: space-between;
}

.mad-amount-title {
  font-size: var(--font-size-body-2);
  height: 2.5rem;
}

.mad-amount-title span {
  font-weight: bold;
}

.dollar-amount-display {
  display: flex;
  min-height: 4rem;
  margin: 0.5rem 0;
  justify-content: space-between;
  background: linear-gradient(to right, #2f9e44, #1878c7);
}

.monthly-amount,
.upfront-amount {
  padding: 1rem;
  font-weight: 700;
  text-align: center;
  color: var(--color-alabaster);
}

.mad-amount-hint {
  width: 100%;
  margin-top: 0.3rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--color-oslo-gray);
}

.rc-slider-handle {
  opacity: 1;
}

.rc-slider-tooltip-content {
  position: absolute;
  top: -35px;
  left: -8px;
  display: none;
}

.percent-slider .rc-slider-handle:hover .rc-slider-tooltip-content,
.rc-slider-tooltip-content.dragging {
  display: block;
}

.rc-slider-tooltip-content.is-narrow {
  left: -6px;
}

.rc-slider-tooltip-arrow {
  border-top-color: #6c6c6c;
  border-width: 4px 4px 0;
  bottom: -4px;
  left: 50%;
  margin-left: -4px;
}

.rc-slider-tooltip-inner {
  min-width: fit-content;
}
