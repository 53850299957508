.line-item {
  display: flex;
  position: relative;
  align-items: center;
  font-size: var(var(--font-size-body-1));
  padding: 0.5rem 4.4rem 0.5rem 0.8rem;
  margin: 0;
  border-color: rgba(0, 0, 0, 0.05);
  border-style: solid none none;
  border-width: 1px;
  min-height: 5.4rem;
}

.line-item:first-child {
  margin-top: 0;
  border-top-style: none;
}

.line-item:hover {
  background-color: var(--color-whisper);
}

.line-item:focus-within {
  background-color: #f6f6f6;
}

.line-item:hover:focus-within {
  background-color: #f2f2f2;
}

.line-item .proposal-text-input {
  margin-bottom: 0 !important;
}

.line-item > .cell {
  width: 10%;
  text-align: right;
  box-sizing: border-box;
  padding-left: 0.5rem;
}

.line-item > .cell:first-child {
  flex-grow: 1;
  text-align: left;
  display: flex;
  padding-left: 0;
}

.line-item > .cell.partnerProductName {
  flex-grow: 1;
}

.line-item > .cell label {
  padding: 0 1rem;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.line-item > .lineItemName label {
  padding: 0 !important;
}

.line-item .remove-trigger-wrapper {
  position: absolute;
  display: flex;
  right: 0;
  width: 4.4rem;
  height: 4.4rem;
  justify-content: center;
  align-items: center;
}

.line-item .remove-trigger-wrapper .delete-button {
  width: 3.52rem;
  height: 3.5rem;
  padding: 0;
  font-size: 2.2em;
  color: var(--color-hit-gray);
  background-color: var(--color-athens-gray);
}

.line-item .remove-trigger-wrapper .delete-button:hover,
.line-item .remove-trigger-wrapper .delete-button:focus-visible {
  color: var(--color-outer-space);
  background-image: none;
  background-color: var(--color-alto);
}

.line-item .remove-trigger-wrapper .delete-button:focus-visible {
  background-color: var(--color-whisper) !important;
}

.line-item .remove-trigger-wrapper .delete-button:disabled,
.line-item .remove-trigger-wrapper .delete-button:hover:disabled {
  color: var(--color-iron);
  background-color: var(--color-whisper);
}

.line-item.inactive,
.line-item.has-error {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.65);
  position: relative;
  padding-left: 1.7rem;
}

.line-item.inactive:before,
.line-item.has-error:before {
  position: absolute;
  content: '!';
  width: 1.6rem;
  text-align: center;
  left: 0;
  font-size: 2.4rem;
  font-weight: 700;
  color: var(--color-carnation);
}

.line-items .line-item-notes {
  flex-basis: 100%;
  line-height: 2rem;
  padding: 0 1.5rem;
}

.line-item .red-text {
  color: var(--color-red);
}

.line-item .slick-pdf-wrapper {
  position: absolute;
  display: flex;
  right: 4.5rem;
  height: 2.4rem;
  width: 2.4rem;
}

.line-item .slick-pdf-wrapper .slick-pdf {
  font-size: var(--font-size-body-1);
  color: black;
  display: block;
}

.line-item .slick-link {
  text-decoration: none;
}

/***** MOBILE UI *****/

@media all and (max-width: 570px) {
  /** Make columns vertical **/
  .line-item {
    flex-direction: column;
    padding: 0.5rem;
  }
  .line-item > .cell {
    width: 100% !important;
    padding: 1rem;
  }

  /** Show Cell labels **/
  .line-item > .cell:before {
    content: attr(label-title);
    position: absolute;
    z-index: 1;
  }
  .line-item > .cell.label:before {
    left: 2.5rem;
  }
  .line-item > .cell.input:before {
    left: 4.5rem;
    margin-top: 1.2rem;
    color: #999;
  }

  /** Position lineItemName **/
  .line-item > .cell:first-child {
    display: block;
  }
  .line-item > .lineItemName label {
    padding: 0 1rem !important;
  }
}
