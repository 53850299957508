.options-list-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  min-width: 25%;
  max-width: 100%;
  height: auto;
  max-height: 45vh;
  padding-bottom: var(--indent-standard);
  background-color: var(--color-white);
  border-radius: var(--radius-light);
  box-shadow: 0 0.4rem 1.6rem rgba(33, 37, 41, 0.24);
  overflow: hidden;
  z-index: 4;
}

.options-list-container.full-width-option-list {
  width: 100%;
}

.autocomplete-option-list {
  height: 100%;
  overflow: auto;
}

.autocomplete-option-list::-webkit-scrollbar {
  width: 0.8rem;
}

.autocomplete-option-list::-webkit-scrollbar-track {
  background-color: var(--color-alabaster);
  box-shadow: inset 1px 0 0.4rem var(--color-quill-gray);
}

.autocomplete-option-list::-webkit-scrollbar-thumb {
  border-radius: 5rem;
  background-color: var(--color-oslo-gray);
}

.options-list-container .option-list-title {
  padding: var(--indent-high) var(--indent-maximum);
  color: var(--color-oslo-gray);
  background: var(--color-alabaster);
  cursor: default;
}

.autocomplete-option {
  position: relative;
  padding: var(--indent-high) var(--indent-maximum);
  border-top: 1px solid var(--color-ghost);
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
}

.autocomplete-option * {
  pointer-events: none;
}

.autocomplete-option.selected-option {
  color: var(--color-bahama-blue);
}

.autocomplete-option:hover,
.autocomplete-option:focus {
  background-color: var(--color-porcelain);
}
