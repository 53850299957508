.catalog-list-item {
  display: grid;
  min-height: 5.6rem;
  padding: 0 var(--indent-high);
  grid-template-columns:
    4% calc(46% - (var(--indent-high) + var(--indent-standard)) * 2)
    17% 17% 9% 7%;
  grid-gap: 0 var(--indent-standard);
  align-items: center;
  color: var(--color-shark);
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-iron);
  cursor: pointer;
}

.catalog-list-item.item-selected,
.catalog-list-item.item-force-disabled,
.catalog-list-item.item-suppressed {
  cursor: default;
}

.catalog-list-item .item-name,
.catalog-list-item .item-type,
.catalog-list-item .item-price {
  padding: var(--indent-high) 0;
}

.catalog-list-item.item-suppressed {
  color: var(--color-oslo-gray);
}

.catalog-list-item.item-selected.item-force-disabled {
  background-color: var(--color-solitude);
  color: var(--color-nevada);
}

.catalog-list-item-sales-ops {
  grid-template-columns:
    4% calc(43% - (var(--indent-high) + var(--indent-standard)) * 2)
    15% 16% 7.5% 6.5% 7%;
}

.catalog-list-item .bundle-price {
  color: var(--color-hit-gray);
}

.catalog-list-item .bundle-price:after {
  content: '*';
  color: var(--color-chateau-green);
  padding-left: 0.3rem;
}

@media all and (max-width: 768px) {
  .catalog-list-item {
    grid-template-columns: 5% 1fr 20%;
    grid-template-rows: auto auto;
  }
}

.catalog-list-item.item-selected {
  font-weight: 600;
  background-color: var(--color-granny-apple);
  cursor: auto;
}

.catalog-list-item.item-pre-selected {
  background-color: var(--color-zircon);
  cursor: auto;
}

.catalog-list-item.item-checked {
  color: var(--color-dodger-blue);
  background-color: var(--color-lilly-white);
}

.catalog-list-item .icon-check {
  font-size: 1.2em;
}

.catalog-list-item-checkbox-component {
  margin: 0;
}

.catalog-list-item-checkbox-component-suppress {
  margin: 0;
  justify-content: center;
}

.catalog-list-item .slick-pdf {
  font-size: calc(var(--size-one-pt) * 3);
  color: black;
  text-decoration: none;
  margin-left: var(--indent-low);
}

@media all and (max-width: 768px) {
  .icon-check,
  .catalog-list-item-checkbox-component,
  .catalog-list-item-checkbox-component-suppress,
  .item-price {
    grid-row-start: 1;
    grid-row-end: span 2;
    font-weight: 600;
  }

  .item-name {
    grid-row-start: 1;
    grid-row-end: 2;
    font-size: 1.1em;
    font-weight: 600;
  }

  .item-type {
    grid-column-start: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    font-size: 0.9em;
    font-weight: 300;
  }
}

.catalog-list-item-checkbox-suppress,
.catalog-list-item-checkbox {
  height: calc(3 * var(--size-one-pt));
}

.catalog-list-item-checkbox .checkbox-box,
.catalog-list-item-checkbox-suppress .checkbox-box {
  position: relative;
  top: 0;
  left: 0;
}

.catalog-list-item-checkbox-suppress .checkbox-box {
  cursor: pointer;
}
