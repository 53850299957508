.label-field-component {
  display: flex;
  align-items: center;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
}

.input-abstract-component.text-right .label-field-component {
  justify-content: flex-end;
}

.input-abstract-component.text-right {
  display: grid;
  grid-template-columns: auto 1fr;
}
