.autocomplete-input-component {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.autocomplete-input-component input[readonly] {
  cursor: pointer;
}

.autocomplete-input-component input[disabled] {
  cursor: not-allowed;
}

.autocomplete-input-component:focus-within .icon-search,
.autocomplete-input-component:focus-within .loader {
  color: var(--color-cerulean-blue);
}

.autocomplete-input-component input {
  padding-left: 4.2rem !important;
}

.autocomplete-input-component:focus-within input {
  padding-right: 40%;
}

@media all and (min-width: 768px) and (max-width: 1440px) {
  .autocomplete-input-component:focus-within input {
    padding-right: 35%;
  }
}

.autocomplete-input-component .field-icon {
  position: absolute;
  top: 0;
  right: 0.8rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.8rem;
  color: var(--color-black-500);
  pointer-events: none;
}

.autocomplete-input-component .field-icon.icon-search {
  right: auto;
  left: 0.8rem;
}

.autocomplete-input-component .field-icon.icon-play-arrow {
  color: var(--color-shark);
  transform: rotate(90deg);
}

.autocomplete-input-component input[disabled] ~ .field-icon {
  color: var(--color-ghost);
}

.autocomplete-input-component .error {
  color: var(--color-carnation) !important;
}

.autocomplete-input-component .option-error {
  position: absolute;
  display: flex;
  right: 3.2rem;
  top: 1.2rem;
  align-items: center;
  place-content: center;
  background-color: var(--color-carnation);
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 50%;
  color: var(--color-alabaster);
  font-size: var(var(--font-size-body-1));
}

@keyframes anim-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
