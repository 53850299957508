.discount-confirmation-container {
  display: flex;
  align-items: baseline;
  border-bottom: var(--size-thin-line) solid var(--color-border);
  margin: calc(-2 * var(--size-one-pt)) calc(-2 * var(--size-one-pt)) 0 calc(-2 * var(--size-one-pt));
  padding: calc(2 * var(--size-one-pt)) calc(2 * var(--size-one-pt)) var(--size-one-pt) calc(2 * var(--size-one-pt));
}

.discount-confirmation-checkbox {
  width: 50%;
}

.discount-confirmation-container.with-additional-discount-fields .discount-confirmation-checkbox {
  width: 25%;
}

.discount-confirmation-checkbox .checkbox-input-abstract {
  margin-bottom: 0;
}

.discount-confirmation-checkbox .checkbox-input-component {
  display: flex;
  height: auto;
  flex: 0 0 100%;
}

.discount-confirmation-checkbox label {
  line-height: 2.4rem;
  margin-left: 4rem;
  width: calc(100% - 9rem);
  cursor: pointer;
}

.discount-confirmation-checkbox .icon-check-box {
  position: absolute;
  top: 0.5rem;
  left: var(--size-quarter-pt);
}

.locked {
  cursor: not-allowed;
}

.locked label {
  cursor: not-allowed;
}

.locked label::before {
  color: var(--color-hit-gray) !important;
}

.discount-confirmation-table {
  width: 50%;
}

.discount-confirmation-container.with-additional-discount-fields .discount-confirmation-table {
  width: 75%;
}

.confirmation-table-row {
  display: flex;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  align-items: center;
}

.confirmation-table-row .input-abstract-component {
  margin: 0;
  width: auto;
}

.confirmation-table-row > span {
  flex-grow: 2;
}

.confirmation-table-row > span.value-cell {
  flex-grow: 1;
  text-align: right;
}

.confirmation-table-row.persist-line-height > .value-cell {
  line-height: 4rem;
}

.discount-confirmation-checkbox .confirmation-spiff-link {
  margin-top: var(--size-one-pt);
  margin-left: 4rem;
  display: inline-block;
}

.discount-confirmation-container .mad-recurring,
.discount-confirmation-container .mad-upfront {
  display: grid;
  grid-column-gap: var(--size-one-pt);
  grid-template-columns: 1.5fr 1fr;
}

@media all and (max-width: 768px) {
  .discount-confirmation-container {
    flex-direction: column;
  }

  .discount-confirmation-table,
  .discount-confirmation-checkbox {
    width: 100%;
    padding: var(--indent-standard);
  }
}

.extended-discount-spiff-table {
  border: none;
}

.extended-discount-spiff-table th {
  font-weight: 400 !important;
  background-color: initial !important;
}

.extended-discount-spiff-table tbody > tr > td {
  vertical-align: initial;
}

.extended-discount-spiff-table tbody .input-abstract-component {
  margin-bottom: 0;
}

.discount-confirmation-table td {
  border-top: none !important;
}

.discount-confirmation-table .label-table-header {
  text-align: right !important;
}
