@font-face {
  font-family: 'icomoon';
  src:
    url('fonts/icomoon.ttf?ltcoke') format('truetype'),
    url('fonts/icomoon.woff?ltcoke') format('woff'),
    url('fonts/icomoon.svg?ltcoke#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-minus-circle:before {
  content: '\e920';
}
.icon-check-circle:before {
  content: '\e921';
}
.icon-radio-button-checked:before {
  content: '\e900';
}
.icon-radio-button_unchecked:before {
  content: '\e901';
}
.icon-check-box-unchecked:before {
  content: '\e902';
}
.icon-check-box-checked:before {
  content: '\e903';
}
.icon-more-vert:before {
  content: '\e904';
}
.icon-calculate:before {
  content: '\e905';
}
.icon-calculate-outlined:before {
  content: '\e90e';
}
.icon-calendar:before {
  content: '\e906';
}
.icon-copy:before {
  content: '\e907';
}
.icon-flag:before {
  content: '\e908';
}
.icon-pdf:before {
  content: '\e909';
}
.icon-notification:before {
  content: '\e90a';
}
.icon-info:before {
  content: '\e90b';
}
.icon-arrow-back:before {
  content: '\e90c';
}
.icon-exit:before {
  content: '\e90d';
}
.icon-menu:before {
  content: '\e924';
}
.icon-history:before {
  content: '\e90f';
}
.icon-apps:before {
  content: '\e910';
}
.icon-play-arrow:before {
  content: '\e911';
}
.icon-expand-less:before {
  content: '\e912';
}
.icon-expand-more:before {
  content: '\e913';
}
.icon-error:before {
  content: '\e914';
}
.icon-edit:before {
  content: '\e915';
}
.icon-close:before {
  content: '\e916';
}
.icon-chevron-right:before {
  content: '\e917';
}
.icon-chevron-left:before {
  content: '\e918';
}
.icon-delete:before {
  content: '\e919';
}
.icon-warning:before {
  content: '\e91a';
}
.icon-search:before {
  content: '\e91b';
}
.icon-add:before {
  content: '\e91c';
}
.icon-check:before {
  content: '\e91d';
}
.icon-last-page:before {
  content: '\e91e';
}
.icon-first-page:before {
  content: '\e91f';
}
.icon-spinner:before {
  content: '\e97a';
}
.icon-marker:before {
  content: '\e933';
}
.icon-download:before {
  content: '\e922';
}
.icon-visibility:before {
  content: '\e923';
}
.icon-visibility-outlined:before {
  content: '\e925';
}
