.quote-device-catalog {
  display: flex;
  flex-direction: column;
  border-radius: var(--radius-light);
  overflow: hidden;
  flex-grow: 1;
}

.catalog-list-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
}

/* --indent-standard summing to other indentation for add the width of the scroll in the content section */
.catalog-list-header {
  display: grid;
  height: 5.6rem;
  padding-left: var(--indent-high);
  padding-right: calc(var(--indent-high) + var(--indent-standard));
  grid-template-columns:
    4% calc(46% - (var(--indent-high) + var(--indent-standard)) * 2)
    17% 17% 9% 7%;
  grid-column-start: 3;
  grid-gap: 0 var(--indent-standard);
  place-content: stretch;
  flex-shrink: 0;
  color: var(--color-nevada);
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-ghost);
}

.catalog-list-header-sales-ops {
  grid-template-columns:
    4% calc(43% - (var(--indent-high) + var(--indent-standard)) * 2)
    15% 16% 7.5% 6.5% 7%;
}

@media all and (max-width: 768px) {
  .catalog-list-header {
    grid-template-columns: auto 1fr 20%;
    grid-gap: 0;
  }

  .header-item:nth-child(2):before {
    content: '/';
    padding: 0 var(--indent-standard);
    color: var(--color-nevada);
    font-weight: normal;
  }
}

.header-item {
  display: flex;
  padding: 0;
  align-items: center;
  background-color: transparent;
  border-radius: 0;
  color: inherit;
  font-weight: 500;
  text-transform: none;
  cursor: pointer;
}

.header-item.sorted {
  font-weight: 600;
  color: var(--color-cerulean-blue);
}

.header-item.header-center-aligned {
  justify-content: center;
}

.header-item:not([disabled]):hover {
  color: var(--color-shark);
  font-weight: 600;
  background-image: none;
}

.header-item.sorted:not([disabled]):hover {
  color: var(--color-cerulean-blue);
}

.header-item:not([disabled]):focus {
  font-weight: 700;
}

.header-item[class*='icon-'] {
  font-size: 1em;
}

.header-item[class*='icon-']::before {
  font-size: 0.7em;
}

.header-item .item-icon-group {
  display: grid;
  padding-left: var(--indent-standard);
  grid-template-rows: 1fr 1fr;
}

.header-item .item-icon-group span {
  display: none;
  padding: 0;
  font-size: 0.8em;
  font-weight: inherit;
  color: var(--color-oslo-gray);
  background: transparent;
  transform: rotateZ(90deg);
}

.header-item .item-icon-group.desc .desc-btn {
  grid-row: 2 / 2;
}

.header-item:hover .item-icon-group span {
  display: block;
}

.header-item .item-icon-group.asc .asc-btn,
.header-item .item-icon-group.desc .desc-btn {
  display: block;
  color: inherit;
}

.quote-device-catalog .filter-controls {
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-iron);
  display: grid;
  grid-template-columns: auto 24rem;
  padding-right: var(--indent-high);
}
.quote-device-catalog .filter-controls.with-solution-type {
  grid-template-columns: auto 38rem;
}

.quote-device-catalog .filter-controls .group-by-wrapper {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}
.quote-device-catalog .filter-controls.with-solution-type .group-by-wrapper {
  grid-template-columns: auto auto auto;
}

.quote-device-catalog .filter-controls .group-by-wrapper > span {
  padding-right: var(--indent-high);
}

.quote-device-catalog .filter-controls .group-by-wrapper .group-by-select-component {
  margin-bottom: 0;
}
.quote-device-catalog .filter-controls.with-solution-type .group-by-wrapper .group-by-select-component {
  padding-right: var(--indent-high);
}

.quote-device-catalog .filter-controls .group-by-wrapper .solution-type-filter-component {
  margin-bottom: 0;
}
.quote-device-catalog .filter-controls .group-by-wrapper .solution-type-filter > .select-option-list {
  max-width: unset;
  left: unset;
  right: 0;
}

.catalog-filter-field {
  position: relative;
  display: grid;
  padding: var(--indent-medium) var(--indent-high);
  grid-template-columns: 1fr;
  flex-shrink: 0;
  align-items: center;
}

.catalog-filter-field .filter-input-component {
  margin: 0;
}

.catalog-filter-field .icon-search {
  position: absolute;
  display: block;
  left: 2.5rem;
  transform: rotateY(180deg);
  font-size: 1.8em;
  color: var(--color-oslo-gray);
  z-index: 2;
}

.catalog-filter-field .filter-input {
  flex: 0 0 36rem;
}

.catalog-filter-field .clear-btn {
  position: absolute;
  display: flex;
  right: calc(var(--indent-standard) + var(--indent-high));
  padding: var(--indent-low) var(--indent-low) var(--indent-low) var(--indent-standard);
  max-height: 4rem;
  align-items: center;
  color: var(--color-alabaster);
  text-transform: lowercase;
  background: var(--color-dodger-blue);
  border-radius: 5rem;
}

.catalog-filter-field .clear-btn.no-items {
  text-transform: capitalize;
  background-color: var(--color-cinnabar);
}

.catalog-filter-field .clear-btn:hover,
.catalog-filter-field .clear-btn:focus {
  background-image: none;
}

.catalog-filter-field .clear-btn .icon-close {
  margin-left: var(--indent-standard);
  font-size: 1.3em;
}

.catalog-list-content {
  position: relative;
  flex-grow: 1;
  overflow: auto;
  border-bottom: 1px solid var(--color-ghost);
}

.catalog-list-content.no-items {
  display: flex;
  color: var(--color-nevada);
  align-items: center;
  justify-content: center;
}

.catalog-list-content::-webkit-scrollbar {
  width: 0.8rem;
}

.catalog-list-content::-webkit-scrollbar-track {
  background-color: var(--color-alabaster);
  box-shadow: inset 1px 0 0.4rem var(--color-quill-gray);
}

.catalog-list-content::-webkit-scrollbar-thumb {
  border-radius: 5rem;
  background-color: var(--color-oslo-gray);
}

.catalog-list-brand input[type='checkbox'] {
  display: none;
}

.catalog-list-brand .icon-expand-less {
  display: flex;
  align-items: center;
  transform: rotateX(180deg);
}

.catalog-list-brand.list-open label > .icon-expand-less {
  transform: none;
}

.catalog-list {
  display: none;
}

.catalog-list-brand.list-open .catalog-list {
  display: block;
}

.catalog-list-brand-title {
  position: sticky;
  display: grid;
  top: 0;
  padding: var(--indent-high);
  grid-template-columns: 4% 1fr;
  grid-column-gap: var(--indent-standard);
  font-weight: 600;
  color: var(--color-nevada);
  background-color: var(--color-white-smoke);
  border-bottom: 1px solid var(--color-alto);
  z-index: 2;
  cursor: pointer;
}

.bundle-price-notation {
  background-color: var(--color-athens-gray);
  color: var(--color-oslo-gray);
  border-top: 1px solid var(--color-ghost);
  line-height: 2rem;
  padding: 0.7rem 2rem;
  position: relative;
}

.bundle-price-notation:before {
  content: '*';
  color: var(--color-chateau-green);
  position: absolute;
  top: 0.7rem;
  left: 0.7rem;
  display: block;
}

.catalog-list-brand.list-open.single-group .catalog-list-brand-title,
.catalog-list-brand.list-open.always-expanded .catalog-list-brand-title {
  cursor: default !important;
}

.catalog-list-brand.list-open.single-group .catalog-list-brand-title > .icon-expand-less,
.catalog-list-brand.list-open.always-expanded .catalog-list-brand-title > .icon-expand-less {
  opacity: 0;
}
.catalog-list-brand.hide-group-name .catalog-list-brand-title {
  display: none !important;
}
