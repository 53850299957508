.calendar-component {
  display: grid;
  grid-template-columns: repeat(7, auto);
}
.calendar-component:focus {
  outline: none;
}

.week-days {
  padding: 0.25em;
  justify-self: center;
  color: var(--color-oslo-gray);
  text-transform: capitalize;
}

.week-days.selected {
  font-weight: 600;
  color: var(--color-black-900);
}

.day-btn-component {
  display: flex;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0.25em;
  align-items: center;
  justify-content: center;
  justify-self: center;
  font-weight: 600;
  background-color: transparent;
  border-radius: var(--size-half-pt);
  border: 0.2rem solid transparent;
}
.day-btn-component:focus {
  outline: none;
}

.day-btn-label {
  letter-spacing: 0;
  pointer-events: none;
}

.day-btn-component:disabled {
  cursor: default;
}

.day-btn-component.gray-btn {
  font-weight: 400;
}

.day-btn-component.selected-day {
  color: var(--color-white);
  background-color: var(--color-primary-500);
}

.day-btn-component:not(.selected-day).preselected-day {
  border: dashed var(--color-hit-gray);
}
.day-btn-component.selected-day.preselected-day:focus {
  border-color: var(--color-porcelain);
  box-shadow: 0 0 0 0.4rem rgba(34, 138, 230, 0.6);
}

.day-btn-component:not([disabled]):hover,
.day-btn-component:active {
  background-image: none;
}

.day-btn-component:not(.selected-day):not(:disabled):hover {
  background-color: var(--color-porcelain);
}

.day-btn-component:not(.selected-day):not(:disabled):focus {
  background-color: var(--color-porcelain);
  border: solid var(--color-curious-blue);
  box-shadow: 0 0 0 0.4rem rgba(34, 138, 230, 0.6);
}

.day-btn-component:not(.selected-day):disabled,
.day-btn-component.gray-btn:not(.selected-day) {
  color: var(--color-hit-gray);
}
