.input-abstract-component.auto-columns {
  grid-auto-columns: auto;
}

.select-input-component {
  position: relative;
  cursor: pointer;
}

.select-input-component input[readonly] {
  cursor: pointer;
}

.select-input-component input[disabled] {
  cursor: not-allowed;
}

.select-input-component input {
  padding-right: calc(4 * var(--size-one-pt)) !important;
}

.select-option-list {
  position: absolute;
  display: none;
  left: 0;
  min-width: 25%;
  max-width: 100%;
  height: auto;
  max-height: 45vh;
  background-color: #ffffff;
  box-shadow: 0 0.4rem 1.6rem rgba(33, 37, 41, 0.24);
  z-index: 5;
  overflow: auto;
  border-bottom-right-radius: var(--size-half-pt);
  border-bottom-left-radius: var(--size-half-pt);
}

.select-input-component.multi .select-option-list {
  max-height: 35vh;
}

.select-option-list.full-width-option-list {
  width: 100%;
}

.short-input .select-option-list {
  text-align: center;
}

.select-option {
  padding: var(--size-one-pt);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  position: relative;
  min-height: calc(4.5 * var(--size-one-pt));
}

.select-option.selected-option {
  color: #005496;
}

.select-option[disabled] {
  pointer-events: none;
  background: var(--color-alabaster);
  color: var(--color-oslo-gray);
}

.select-option:hover,
.select-option:focus {
  background-color: var(--color-section-header-bg);
}

.select-input-component.icon-to-left input {
  padding-right: 1.2rem;
  padding-left: 4.2rem;
}

.select-input-component .field-icon {
  position: absolute;
  top: 0;
  right: 0.8rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.8rem;
  color: var(--color-ghost);
  pointer-events: none;
}

.select-input-component .field-icon.icon-search {
  right: auto;
  left: 0.8rem;
  transform: rotateY(180deg);
}

.select-input-component .field-icon.icon-play-arrow {
  color: var(--color-shark);
  transform: rotate(90deg);
}

.select-input-component input[disabled] ~ .field-icon {
  display: none;
}

.error {
  color: var(--color-carnation) !important;
}

.option-error {
  position: absolute;
  display: flex;
  right: 3.2rem;
  top: 1.2rem;
  align-items: center;
  place-content: center;
  background-color: var(--color-carnation);
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 50%;
  color: var(--color-alabaster);
  font-size: var(var(--font-size-body-1));
}

.select-input-component.right-align input {
  text-align: right;
}

.select-input-component.multi input {
  text-overflow: ellipsis;
  overflow: hidden;
}

.select-input-component.multi .selected-option {
  font-weight: 600;
  background-color: var(--color-section-header-bg) !important;
  color: initial;
}
