.ictp-providers-header-sales-ops {
  font-weight: 600;
  display: grid;
  grid-template-columns: 1.2fr 2fr 1fr 1fr 1fr;
  grid-column-gap: 5.5rem;
}

.ictp-providers-header {
  font-weight: 600;
  display: grid;
  grid-template-columns: 1.2fr 2fr 1fr 1.5fr 1fr;
  grid-column-gap: 5.5rem;
}

#location-ictp-section .ictp-providers-header {
  grid-template-columns: 1.2fr 2fr 1fr 1fr 1fr 1fr;
}

#location-ictp-section .ictp-providers-header-for-sales {
  grid-template-columns: 1.2fr 2fr 1fr 1fr 1fr 1fr 1fr;
}

.ictp-providers-header div,
.ictp-providers-header-sales-ops div {
  padding: 1.85rem 0;
}

.ictp-providers-header div:first-child,
.ictp-providers-header-sales-ops div:first-child {
  text-align: end;
}

.ictp-providers-header div:nth-child(2),
.ictp-providers-header-sales-ops div:nth-child(2) {
  text-align: start;
}

.ictp-providers-header div:nth-child(n + 3),
.ictp-providers-header-sales-ops div:nth-child(n + 3) {
  text-align: right;
}

.ictp-providers-header > div:last-child,
.ictp-providers-header-sales-ops > div:last-child,
.ictp-provider > div:last-child,
.ictp-provider-sales-ops > div:last-child {
  padding-right: 1rem;
}

.ictp-providers-price {
  display: inline-block;
  padding-left: 2rem;
  box-sizing: border-box;
}

.ictp-acknowledgement {
  padding: 1.5rem;
}

.ictp-acknowledgement .checkbox-input-abstract {
  margin: 0;
}

.ictp-acknowledgement label {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-column-gap: var(--indent-standard);
}
/***** MOBILE UI *****/

@media all and (max-width: 600px) {
  /** Make columns vertical **/
  .ictp-provider {
    grid-template-columns: unset !important;
    padding-top: 1rem;
    height: 100%;
  }
  .ictp-providers-header {
    display: none;
  }
  .ictp-provider > div {
    width: 100% !important;
    padding: 1rem;
  }
}
