.ictp-provider,
.ictp-provider-sales-ops {
  border-bottom: var(--size-thin-line) solid rgba(0, 0, 0, 0.05);
  display: grid;
  grid-template-columns: 1.2fr 2fr 1fr 1.5fr 1fr;
  grid-column-gap: 5.5rem;
}

.ictp-provider-sales-ops {
  grid-template-columns: 1.2fr 2fr 1fr 1fr 1fr;
}

.ictp-provider:last-child,
.ictp-provider-sales-ops:last-child {
  border-bottom-style: none;
}

.ictp-provider:hover,
.ictp-provider-sales-ops:hover {
  background-color: var(--color-whisper);
}

#location-ictp-section .ictp-provider {
  grid-template-columns: 1.2fr 2fr 1fr 1fr 1fr 1fr;
}

.ictp-provider > div:first-child,
.ictp-provider-sales-ops > div:first-child {
  text-align: end;
}

.ictp-provider > div:nth-child(2),
.ictp-provider-sales-ops > div:nth-child(2) {
  text-align: start;
}

.ictp-provider > div:nth-child(n + 3),
.ictp-provider-sales-ops > div:nth-child(n + 3) {
  text-align: right;
}

.ictp-provider-label {
  padding: 1.85rem 0;
}

.ictp-provider-options {
  display: grid;
  align-items: center;
}

#customer-order-ictp-section .ictp-provider-options .input-abstract-component {
  width: 100%;
}

.ictp-provider-options > label {
  padding-left: 1.6rem;
  font-size: 1.4rem;
}

.ictp-provider-price {
  display: inline-block;
  padding: 0.8rem 0;
}

.ictp-price-label {
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
}

/***** MOBILE UI *****/

@media all and (min-width: 600px) {
  .ictp-price-label-name {
    display: none;
  }

  #ictp-override-header,
  .ictp-override-price {
    left: 1rem;
  }
}

@media all and (max-width: 600px) {
  .ictp-price-label {
    padding-right: 1rem;
  }

  .ictp-provider-label {
    display: flex;
    justify-content: space-between;
  }

  .ictp-provider-label:first-of-type {
    display: block;
  }

  .ictp-price-label-name {
    font-weight: 600;
  }

  .ictp-provider-price {
    display: flex;
    justify-content: space-between;
  }

  .ictp-provider-price > .input-abstract-component {
    width: 50%;
  }

  #customer-order-ictp-section .ictp-provider-options .input-abstract-component {
    width: 100%;
  }
}
