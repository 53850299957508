.contacts-section,
.contacts-section > div {
  display: grid;
  grid-column-gap: var(--size-one-pt);
  grid-row-gap: calc(3 * var(--size-one-pt));
}
.contacts-section {
  grid-template-columns: 1fr 1fr;
}

.contacts-section > div {
  grid-auto-rows: minmax(min-content, max-content);
}
