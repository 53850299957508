.slex-dialog-content {
  width: 90vw;
}

.slex-modal-table > tbody > tr > .crm-link {
  max-width: calc(40 * var(--size-one-pt));
}

.slex-modal-table > tbody > tr > .crm-link > a {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
