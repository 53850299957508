.lease-application-form > .fieldset {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: calc(20 * var(--size-one-pt)) auto;
  grid-gap: var(--size-one-pt);
}

.lease-application-form label > small {
  display: block;
}
.lease-application-form .input-abstract-component {
  margin-bottom: 0;
}

.lease-application-form .emails-section {
  display: grid;
  grid-template-columns: calc(20 * var(--size-one-pt)) auto auto;
  grid-gap: var(--size-one-pt);
  grid-template-areas:
    '. . cc-emails-label'
    'label email cc-email-one'
    'label quick-quote-toggle cc-email-two';
  margin-top: var(--size-one-pt);
}
.lease-application-form .emails-section > label.main {
  grid-area: label;
}
.lease-application-form .emails-section > label.cc-emails-label {
  grid-area: cc-emails-label;
}
.lease-application-form .emails-section > .input-abstract-component.email {
  grid-area: email;
}

.lease-application-form .emails-section > .text-input-field.cc-email-one {
  grid-area: cc-email-one;
}

.lease-application-form .emails-section > .text-input-field.cc-email-two {
  grid-area: cc-email-two;
}

.lease-application-form .emails-section > .quick-quote-toggle {
  grid-area: quick-quote-toggle;
}
