.card-section.order-initiation-wrapper {
  margin-top: calc(10 * var(--size-one-pt));
}

.order-initiation-wrapper .toolbar.max-width-container {
  max-width: inherit;
  justify-content: flex-end;
  align-items: center;
}

@media all and (max-width: 570px) {
  .order-initiation-wrapper .toolbar-actions-component {
    padding: var(--indent-high) 0;
  }
}

.order-initiation {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
}

.order-initiation .quote-card-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
}
.order-initiation .quote-card-container .card-content > .input-abstract-component {
  margin-top: calc(3 * var(--size-one-pt));
  margin-bottom: 0;
}
.order-initiation .quote-card-container .card-content > .input-abstract-component:first-child {
  margin-top: 0;
}
.order-initiation .quote-card-container .card-content > .input-abstract-component.partnerInfoLabel,
.order-initiation
  .quote-card-container
  .card-content
  > .input-abstract-component.partnerInfoLabel
  + .input-abstract-component {
  margin-top: 0;
}

.order-initiation .card-header.sub-header {
  border-radius: 0;
  color: var(--color-shark);
  font-size: 1.4rem;
  text-transform: none;
  font-weight: normal;
  padding-top: 0;
  padding-bottom: 0;
}

.order-initiation .card-header .card-header-title {
  cursor: default;
  padding-left: calc(2 * var(--size-one-pt));
}

.order-initiation .card-content {
  padding: calc(2 * var(--size-one-pt));
  border: none;
}

.order-initiation .radio-input-group-component {
  margin-top: 0;
}

@media all and (min-width: 768px) {
  .order-initiation .input-abstract-component,
  .order-initiation-actions-bottom {
    width: 100%;
  }
}

.order-initiation .card-footer button {
  border-radius: var(--radius-light);
  border: none;
  max-width: 13em;
  height: 4rem;
  display: grid;
  flex-shrink: 0;
  margin: 0.3rem;
  padding: 0 2rem;
  background-color: var(--color-alabaster);
  color: var(--color-alabaster);
  font-size: var(var(--font-size-body-1));
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  outline: none;
}

.order-initiation-wrapper .start-quote-btn {
  color: var(--color-alabaster);
  background-color: var(--color-chateau-green);
}

.order-initiation-wrapper .cancel-quote-btn {
  color: var(--color-alabaster);
  background-color: transparent;
  border: 1px solid var(--color-alabaster);
}

.order-initiation .order-type-select .input-abstract-label-wrapper {
  align-self: flex-start;
}

.order-initiation .radio-input-group {
  flex-direction: column;
}

.order-initiation .radio-input {
  padding: 0.6rem 2.4rem 0 0;
  background-color: var(--color-white);
}

.order-initiation .radio-input label {
  align-self: center;
}

.order-initiation .radio-input:first-of-type {
  padding-top: 0;
  border-bottom: none;
  border-top-left-radius: var(--radius-light);
  border-top-right-radius: var(--radius-light);
}

.order-initiation .radio-input:last-of-type {
  border-top: none;
  border-bottom-left-radius: var(--radius-light);
  border-bottom-right-radius: var(--radius-light);
}

.order-initiation .radio-input:only-of-type {
  border-top: 1px solid var(--color-alto);
  border-bottom: 1px solid var(--color-alto);
}

.order-initiation-actions-bottom.btn-collection {
  justify-content: end;
  margin-top: calc(3 * var(--size-one-pt));
}

#partnerInfoLabel {
  font-weight: initial;
  font-size: var(--font-size-body-2);
  color: var(--color-black-600);
}

#industry-type-select.select-input > .options {
  max-height: calc(4 * 5 * var(--size-one-pt) + var(--size-one-pt));
}
