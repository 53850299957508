.copy-contacts-wrapper {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-column-gap: var(--indent-high);
  font-weight: 500;
}

.copy-contacts-input .input-abstract-component {
  display: grid;
  grid-template-columns: auto 1fr;
}

.copy-contacts-checkbox {
  width: 100%;
}

.copy-contacts-checkbox .checkbox-input-component {
  display: grid;
  align-content: center;
}

.copy-contacts-checkbox .checkbox-input-component label {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-column-gap: var(--indent-standard);
}

.copy-contacts-checkbox .checkbox-input-component label .checkbox-box {
  position: static;
}
