.location-select-component {
  position: relative;
}

.location-select-input {
  position: absolute;
  width: calc(34 * var(--size-one-pt));
  right: 0;
  min-height: 0;
}

.location-select-input.left {
  left: 0;
}

.location-select {
  display: none;
  background-color: white;
  z-index: 2;
  box-shadow: 0 0.4rem 1.6rem rgba(33, 37, 41, 0.24);
  border-bottom-right-radius: var(--size-half-pt);
  border-bottom-left-radius: var(--size-half-pt);
  width: calc(34 * var(--size-one-pt));
}

.location-select > .select-option-list {
  position: relative;
  box-shadow: none;
  top: 0 !important;
}

.show-location-select {
  display: block;
}

.location-select > input {
  margin: var(--size-one-pt);
  position: relative;
  width: calc(100% - 2 * var(--size-one-pt)) !important;
  padding-left: 3rem !important;
}

.location-select > .field-icon {
  position: absolute;
  top: 14px;
  left: 10px;
  height: auto;
  right: auto;
}
