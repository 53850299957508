.badge-btn-component {
  position: relative;
  display: inline-grid;
  margin: 0;
}
.badge-btn-component > button,
.badge-btn-component > .btn {
  margin: 0;
}

.badge-btn-component:hover .badge-btn-errors-list {
  display: grid;
}

.badge-btn-component .clone-btn + .badge-btn-errors-list .badge-btn-tooltip {
  left: 70%;
}

.badge-btn-component .clone-btn + .badge-btn-errors-list .badge-btn-tooltip::after {
  content: '';
  right: 72%;
}

.badge-btn {
  position: relative;
}

.badge-btn * {
  pointer-events: none;
}

.badge-btn-badge {
  position: absolute;
  display: block;
  top: calc(-1 * var(--size-one-pt));
  right: calc(-0.7 * var(--size-one-pt));
  width: calc(3 * var(--size-one-pt));
  height: calc(3 * var(--size-one-pt));
  font-size: var(--font-size-body-2);
  font-weight: 600;
  border-radius: 100%;
  background-color: var(--color-danger-500);
  color: white;
  z-index: 1;
  padding: 0;
  margin: 0;
  text-align: center;
  line-height: calc(3 * var(--size-one-pt));
}

.badge-btn-badge.info-tooltip {
  box-shadow: 0 0 0.4rem var(--color-chateau-green);
  background-color: var(--color-alabaster);
  color: var(--color-chateau-green);
}

.badge-btn-badge.disabled {
  filter: grayscale(1);
}
