.toggle-input-abstract {
  display: grid;
  grid-template-columns: auto 1fr;
}

.toggle-input-abstract .input-abstract-label-wrapper {
  flex: 1 1 auto;
}

.toggle-input-abstract .toggle-input-component {
  display: flex;
  flex-basis: auto;
  align-items: center;
  justify-content: flex-end;
}

.toggle-input-abstract input.toggle-checkbox {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
}

.toggle-input-component {
  font-size: 0.72em;
}

.toggle-input-abstract > .input-abstract-label-wrapper > label,
.toggle-switch {
  cursor: pointer;
}
.toggle-input-abstract.disabled > .input-abstract-label-wrapper > label {
  cursor: default;
}

.toggle-switch {
  position: relative;
  display: flex;
  flex-flow: row;
  width: calc(7 * var(--size-one-pt));
  height: calc(3 * var(--size-one-pt));
  border-radius: calc(1.5 * var(--size-one-pt));
  align-items: center;
  background-color: var(--color-white);
  border: var(--size-thin-line) solid var(--color-black-600);
}

.toggle-switch:focus-visible {
  background-color: var(--color-black-050) !important;
  border-color: var(--color-black-300);
  border-width: 1px;
}

.toggle-input-component.compact .toggle-switch {
  font-size: 1.1em;
}

.toggle-circle {
  width: calc(3 * var(--size-one-pt) - 4 * var(--size-thin-line));
  height: calc(3 * var(--size-one-pt) - 4 * var(--size-thin-line));
  border-radius: 100%;
  background: var(--color-black-600);
  margin: var(--size-thin-line);
}

.toggle-on-text,
.toggle-off-text {
  display: none;
  font-weight: 600;
  color: var(--color-alabaster);
  line-height: 1em;
  flex: 1;
}

.toggle-checkbox:disabled ~ .toggle-switch {
  cursor: not-allowed;
  background-color: #eceded !important;
  border-color: transparent;
}
.toggle-checkbox:disabled ~ .toggle-switch > .toggle-circle {
  background-color: #adb5bd !important;
}

.toggle-checkbox:checked ~ .toggle-switch {
  background-color: var(--color-primary-500);
  border-color: transparent;
  flex-flow: row-reverse;
}

.toggle-checkbox:checked ~ .toggle-switch > .toggle-circle {
  background-color: var(--color-white);
}

.toggle-checkbox:checked ~ .toggle-switch > .toggle-on-text {
  display: block;
  text-align: left;
  padding-left: var(--size-one-pt);
}

.toggle-checkbox:not(:checked) ~ .toggle-switch > .toggle-off-text {
  display: block;
  text-align: right;
  padding-right: var(--size-one-pt);
  color: var(--color-black-600);
}

.toggle-checkbox:disabled ~ .toggle-switch > .toggle-on-text,
.toggle-checkbox:disabled ~ .toggle-switch > .toggle-off-text {
  color: #adb5bd;
}

.toggle-checkbox:focus-visible:checked + .toggle-switch {
  background-color: var(--color-cornflower-royal-blue) !important;
}

.toggle-checkbox:focus-visible:not(:checked) + .toggle-switch {
  background-color: var(--color-lilly-white) !important;
}
